import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = ["outline"];
const _hoisted_3 = {
    key: 0,
    class: "r-text text-sm px-2"
};
const _hoisted_4 = ["onClick"];
import { computed, ref } from 'vue';
import { toast } from '@/common/util';
import { useI18n } from 'vue-i18n';
import { useCursosStore, useFormacoesStore, useMidiaStore } from '@/stores';
import UiIcon from '@/components/ui/icon.vue';
import UiDropdown from '@/components/ui/dropdown.vue';
// import UiButton from '@/components/ui/button.vue'
export default /*@__PURE__*/ _defineComponent({
    __name: 'reactButton',
    props: {
        reaction: {},
        minimized: { type: Boolean },
        typeContent: {},
        contentId: {},
        qtd: {},
        disabled: { type: Boolean },
        typeButton: {}
    },
    emits: ['onSuccess'],
    setup(__props, { emit: __emit }) {
        const { t } = useI18n();
        const emit = __emit;
        const props = __props;
        const cursosStore = useCursosStore();
        const formacoesStore = useFormacoesStore();
        const midiaStore = useMidiaStore();
        const busy = ref(false);
        const icons = {
            heart: 'heart',
            like: 'thumb-up',
            dislike: 'thumb-down',
            confused: 'mood-puzzled',
            sad: 'mood-sad',
            surprised: 'mood-suprised',
            happy: 'mood-happy',
            smile: 'mood-smile',
            empty: 'mood-empty',
            angry: 'mood-angry',
            star: 'star'
        };
        const dropdown = ref();
        const demoReaction = ref('');
        const excludedIcons = ['dislike', 'confused', 'sad', 'empty', 'angry'];
        const randReaction = () => {
            const keys = Object.keys(icons).filter(key => !excludedIcons.includes(key));
            demoReaction.value = keys[Math.floor(Math.random() * keys.length)];
        };
        const reactionIcon = computed(() => icons[props.reaction?.toLowerCase() || demoReaction.value?.toLowerCase()]);
        const setReaction = async (reaction) => {
            let response = null;
            const params = {
                id: props.contentId,
                reacao: reaction
            };
            try {
                busy.value = true;
                if (!['curso', 'formacao', 'course', 'formation'].includes(props.typeContent)) {
                    response = await midiaStore.setReactionMedia(params);
                }
                else if (props.typeContent === 'curso' || props.typeContent === 'course') {
                    response = await cursosStore.setReactionCourse(params);
                }
                else {
                    response = await formacoesStore.setReactionFormation(params);
                }
                if (response.success) {
                    toast.success({
                        message: t(!reaction ? 'toasts.details.reactionCanceled' : 'toasts.details.reactionSuccess')
                    });
                    emit('onSuccess', { ...response.data, reacao: reaction });
                    dropdown.value?.close();
                }
            }
            catch (error) {
                toast.error({
                    message: t('feedback.somethingWrong')
                });
            }
            finally {
                busy.value = false;
            }
        };
        const reactionType = computed(() => {
            if (['heart', 'like', 'surprised', 'happy', 'smile', 'star'].includes(props.reaction?.toLowerCase()))
                return 'positive';
            else if (['empty', 'confused'].includes(props.reaction?.toLowerCase()))
                return 'neutral';
            else if (['dislike', 'sad', 'angry'].includes(props.reaction?.toLowerCase()))
                return 'negative';
            else
                return '';
        });
        randReaction();
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(UiDropdown, {
                ref_key: "dropdown",
                ref: dropdown,
                placement: 'bottom-start',
                disabled: busy.value
            }, {
                default: _withCtx(() => [
                    _createElementVNode("button", {
                        disabled: _ctx.disabled || busy.value,
                        class: _normalizeClass(['reactbtn', reactionType.value, { 'animate-pulse': busy.value }])
                    }, [
                        _createElementVNode("div", {
                            outline: !_ctx.reaction,
                            class: _normalizeClass(['r-icon p-2 flex', { 'py-3 px-4': _ctx.typeButton == 'class', 'py-1': _ctx.typeButton == 'classItem' }]),
                            onMouseenter: _cache[0] || (_cache[0] = ($event) => (!_ctx.disabled && !_ctx.reaction && randReaction()))
                        }, [
                            _createVNode(_Transition, {
                                mode: "out-in",
                                "enter-active-class": "ease-in-out duration-300 transform",
                                "enter-from-class": "opacity-0 -translate-y-2",
                                "leave-active-class": "ease-in-out duration-300 transform",
                                "leave-to-class": "opacity-0 translate-y-2"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(UiIcon, {
                                        icon: reactionIcon.value,
                                        class: "w-full h-6"
                                    }, null, 8, ["icon"])
                                ]),
                                _: 1
                            })
                        ], 42, _hoisted_2),
                        (!_ctx.minimized)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t(!_ctx.reaction ? 'labels.react' : 'labels.reacted')), 1))
                            : _createCommentVNode("", true)
                    ], 10, _hoisted_1)
                ]),
                content: _withCtx(() => [
                    _createElementVNode("div", {
                        class: _normalizeClass([_ctx.typeButton == 'class' ? 'p-[0.65rem]' : 'p-2'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(['grid grid-cols-3 gap-2', { 'grid-cols-5': _ctx.typeButton == 'class' }])
                        }, [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(icons, (icon, key) => {
                                return _createElementVNode("button", {
                                    key: key,
                                    class: _normalizeClass([
                                        'p-2 border border-transparent rounded-lg hover:border-amber-500 transition-all',
                                        _ctx.typeButton == 'class' ? 'p-[0.65rem]' : 'p-2'
                                    ]),
                                    onClick: () => setReaction(key)
                                }, [
                                    _createVNode(UiIcon, {
                                        icon: icon,
                                        class: "w-full h-6 text-center text-amber-500 dark:text-amber-400"
                                    }, null, 8, ["icon"])
                                ], 10, _hoisted_4);
                            }), 64)),
                            (_ctx.reaction)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    class: _normalizeClass([
                                        'border border-red rounded-lg border-rose-400 bg-rose-100 hover:bg-rose-200 text-rose-500 transition-all',
                                        _ctx.typeButton == 'class' ? 'p-[0.65rem]' : 'p-2'
                                    ]),
                                    onClick: _cache[1] || (_cache[1] = () => setReaction(null))
                                }, [
                                    _createVNode(UiIcon, {
                                        icon: "trash",
                                        class: "w-full h-6 text-center"
                                    })
                                ], 2))
                                : _createCommentVNode("", true)
                        ], 2)
                    ], 2)
                ]),
                _: 1
            }, 8, ["disabled"]));
        };
    }
});
