import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { dialog } from '@/common/util';
import avaliacaoService from '@/services/avaliacao.service';
import { assessmentService } from '@/services/avaliacao.service';
export const useAvaliacaoStore = defineStore('avaliacoes', () => {
    const { t } = useI18n();
    const list = ref([]);
    const listAssessmentsStudent = ref([]);
    const listAssessmentsEvaluator = ref([]);
    const listAssessmentsEvaluatorItems = ref([]);
    const status = reactive({
        getAll: 'initial',
        create: 'initial',
        update: 'initial',
        delete: 'initial',
        getById: 'initial',
        bonds: 'initial',
        prepare: 'initial',
        getAssessmentsStudent: 'initial',
        getAssessmentsEvaluator: 'initial',
        getAssessmentsEvaluatorItems: 'initial',
        getStudentAssessmentForCorrection: 'initial',
        sendCorrectionAnswer: 'initial',
        alterCorrectionAnswer: 'initial',
        finishCorrection: 'initial'
    });
    const loading = computed(() => Object.values(status).some(el => el === 'loading'));
    const methodStatus = computed(() => (method, sts) => {
        if (Array.isArray(method))
            return method.some((el) => status[el] === sts);
        return status[method] === sts;
    });
    const clear = (getAll) => {
        if (getAll)
            return list.value.splice(0, list.value.length);
        listAssessmentsEvaluatorItems.value.splice(0, listAssessmentsEvaluatorItems.value.length);
    };
    const getAll = async () => {
        status.getAll = 'loading';
        clear(true);
        try {
            const { data, success } = await avaliacaoService.get();
            if (success) {
                // Normaliza os atributos da avaliação
                list.value = data.map((exam) => fmAvaliacaoResponse(exam));
            }
            status.getAll = 'success';
        }
        catch (error) {
            status.getAll = 'error';
        }
    };
    const getById = async (id) => {
        status.getById = 'loading';
        try {
            const { data, success } = await avaliacaoService.get(id);
            if (success) {
                status.getById = 'success';
                return {
                    id: data.id,
                    title: data.titulo,
                    observation: data.observacao,
                    type: data.tipoDeAvaliacao,
                    duration: data.duracaoDaAvaliacao,
                    skillsIds: data.competenciasIds,
                    score: data.pontuacaoMinima,
                    canRetry: data.permitirNovasTentativas,
                    maxRetries: data.maximoTentativas,
                    maxQuestions: data.maximoQuestoes,
                    shuffleQuestions: data.embaralharQuestoes,
                    shuffleOptions: data.embaralharAlternativas,
                    displayAnswers: data.exibirCorretaseErradas,
                    showTimer: data.exibirTimer,
                    questions: data.questoes.map((questao) => ({
                        id: questao.id,
                        type: questao.tipoDeQuestao,
                        statement: questao.enunciado,
                        scale: questao.escala,
                        score: questao.pontuacaoMaxima,
                        showFeedback: questao.exibirFeedback,
                        feedback: {
                            right: questao.feedbackAcerto,
                            wrong: questao.feedbackErro,
                        },
                        order: questao.ordem,
                        imageLink: questao.anexo,
                        options: questao.alternativas.map((option) => ({
                            id: option.id,
                            text: option.descricao || '',
                            isTrue: option.correta,
                            questionId: option.questaoId
                        })),
                        supportedFiles: {
                            audio: questao.tipoUpload?.audio,
                            camera: questao.tipoUpload?.camera,
                            document: questao.tipoUpload?.documentos,
                            image: questao.tipoUpload?.imagens,
                            microphone: questao.tipoUpload?.microfone,
                            screen: questao.tipoUpload?.tela,
                            video: questao.tipoUpload?.video
                        },
                        answerType: questao.tipoResposta?.toLowerCase() || ''
                    })),
                    active: data.active,
                    categoryId: data.categoriaId,
                    categoryName: data.categoriaNome,
                    evaluatorManager: data.avaliacoesAvaliadores.map((el) => el.usuarioId)
                };
            }
        }
        catch (error) {
            status.getById = 'error';
        }
    };
    const formatData = (dt) => ({
        titulo: dt.title,
        observacao: dt.observation,
        tipoDeAvaliacao: dt.type,
        duracaoDaAvaliacao: dt.showTimer ? dt.duration?.split(':').length > 2 ? dt.duration : `${dt.duration || '00'}:00` : null,
        competenciasIds: dt.skillsIds,
        permitirNovasTentativas: dt.canRetry,
        maximoTentativas: dt.maxRetries,
        maximoQuestoes: dt.maxQuestions,
        pontuacaoMinima: dt.score,
        embaralharQuestoes: dt.shuffleQuestions,
        embaralharAlternativas: dt.shuffleOptions,
        exibirCorretaseErradas: dt.displayAnswers,
        exibirTimer: dt.showTimer,
        questoes: dt.questions.map((question) => (question.id ?? delete question.id) && ({
            id: question.id,
            tipoDeQuestao: question.type,
            enunciado: question.statement,
            escala: question.scale,
            ordem: question.order,
            exibirFeedback: question.showFeedback,
            feedbackAcerto: question.feedback.right,
            feedbackErro: question.feedback.wrong,
            anexo: question.imageLink,
            imagem: (question?.image ? ({
                data: question?.image?.split(',').at(-1),
                mime: question?.image?.split(':').at(-1).split(';').at(0)
            }) : null),
            alternativas: question.options.map((option) => ({
                id: option.id,
                descricao: option.text || '',
                correta: option.isTrue,
                questaoId: option.questionId
            })),
            pontuacaoMaxima: Number(question.score) || 1,
            tipoResposta: question.type === 'Pratica' && question.answerType ? question.answerType : null,
            tipoUpload: question.answerType === 'upload' ? {
                audio: question.supportedFiles?.audio,
                camera: question.supportedFiles?.camera,
                documentos: question.supportedFiles?.document,
                imagens: question.supportedFiles?.image,
                microfone: question.supportedFiles?.microphone,
                tela: question.supportedFiles?.screen,
                video: question.supportedFiles?.video
            } : null
        })),
        active: dt.active,
        categoriaId: dt.categoryId,
        avaliacoesAvaliadores: dt.evaluatorManager.map(el => ({ usuarioId: el }))
    });
    const save = async (dt, id) => {
        const method = id ? 'update' : 'create';
        status[method] = 'loading';
        try {
            const { success, data } = id
                ? await avaliacaoService.update(id, { ...formatData(dt) })
                : await avaliacaoService.create({ ...formatData(dt), active: true });
            if (success)
                getAll();
            status[method] = 'complete';
            return data;
        }
        catch {
            status[method] = 'error';
            return false;
        }
    };
    const remove = async (avaliacao, force) => {
        if (!force) {
            return dialog.open({
                title: t('feedback.attention'),
                message: t('dialogs.delete.message', { type: t('labels.test').toLowerCase(), name: avaliacao.title }),
                complete: () => remove(avaliacao, true)
            });
        }
        try {
            status.delete = 'loading';
            const { success } = await avaliacaoService.remove(avaliacao.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === avaliacao.id), 1);
            status.delete = 'success';
        }
        catch (error) {
            status.delete = 'error';
        }
    };
    const bonds = async (avaliacaoId) => {
        status.bonds = 'loading';
        try {
            const { data } = await avaliacaoService.getBonds(avaliacaoId);
            status.bonds = 'success';
            return data;
        }
        catch {
            status.bonds = 'error';
        }
    };
    const prepare = async (opt) => {
        status.prepare = 'loading';
        try {
            const result = avaliacaoService.getInfo(opt);
            status.prepare = 'success';
            return result;
        }
        catch (error) {
            status.prepare = 'error';
        }
    };
    const fmAvaliacaoResponse = (data) => {
        return {
            id: data.id,
            title: data.titulo,
            observation: data.observacao,
            type: data.tipoDeAvaliacao,
            duration: data.duracaoDaAvaliacao,
            skillsIds: data.avaliacoesCompetencias,
            score: 0,
            canRetry: data.permitirNovasTentativas,
            maxRetries: data.maximoTentativas,
            maxQuestions: data.maximoQuestoes,
            shuffleQuestions: data.embaralharQuestoes,
            shuffleOptions: data.embaralharAlternativas,
            displayAnswers: data.exibirCorretaseErradas,
            showTimer: data.exibirTimer,
            questions: data.questoes,
            active: data.active,
            origin: data.origem,
            categoryId: data.categoriaId,
            categoryName: data.categoriaNome,
            evaluatorManager: data.avaliacoesAvaliadores
        };
    };
    const getAssessmentsStudent = async () => {
        status.getAssessmentsStudent = 'loading';
        try {
            const { data, success } = await assessmentService.getAssessmentsStudent();
            if (success) {
                listAssessmentsStudent.value = data.sort((a, b) => {
                    const dateA = new Date(a.availableIn).getTime();
                    const dateB = new Date(b.availableIn).getTime();
                    return dateA - dateB;
                });
            }
            status.getAssessmentsStudent = 'success';
        }
        catch (error) {
            status.getAssessmentsStudent = 'error';
        }
    };
    const getAssessmentsEvaluator = async () => {
        status.getAssessmentsStudent = 'loading';
        try {
            const { data, success } = await assessmentService.getAssessmentsEvaluator();
            if (success) {
                listAssessmentsEvaluator.value = data;
            }
            status.getAssessmentsStudent = 'success';
        }
        catch (error) {
            status.getAssessmentsStudent = 'error';
        }
    };
    const getAssessmentsEvaluatorItems = async (assessmentId, contentId) => {
        status.getAssessmentsEvaluatorItems = 'loading';
        try {
            const { data, success } = await assessmentService.getAssessmentsEvaluatorItems(assessmentId, contentId);
            if (success) {
                listAssessmentsEvaluatorItems.value = data.sort((a, b) => {
                    const dateA = new Date(a.availableIn).getTime();
                    const dateB = new Date(b.availableIn).getTime();
                    return dateA - dateB;
                });
            }
            status.getAssessmentsEvaluatorItems = 'success';
        }
        catch (error) {
            status.getAssessmentsEvaluatorItems = 'error';
        }
    };
    const getStudentAssessmentForCorrection = async (userAssessmentId) => {
        status.getStudentAssessmentForCorrection = 'loading';
        try {
            const { success, data } = await assessmentService.studentAssessmentForCorrection(userAssessmentId);
            if (success) {
                const formattedData = formatAssessment(data);
                status.getStudentAssessmentForCorrection = 'success';
                return formattedData;
            }
        }
        catch (error) {
            status.getStudentAssessmentForCorrection = 'error';
            throw error;
        }
    };
    const sendCorrectionAnswer = async (opts) => {
        status.alterCorrectionAnswer = 'loading';
        try {
            const { success, data } = await assessmentService.sendCorrectionAnswer(opts);
            if (success) {
                status.alterCorrectionAnswer = 'success';
                return data;
            }
        }
        catch (error) {
            status.alterCorrectionAnswer = 'error';
        }
    };
    const alterCorrectionAnswer = async (opts) => {
        status.alterCorrectionAnswer = 'loading';
        try {
            const { success, data } = await assessmentService.alterCorrectionAnswer(opts);
            if (success) {
                status.alterCorrectionAnswer = 'success';
                return data;
            }
        }
        catch (error) {
            status.alterCorrectionAnswer = 'error';
        }
    };
    const finishCorrection = async (userAssessmentId, finalConsiderations) => {
        status.finishCorrection = 'loading';
        try {
            const { success, data } = await assessmentService.finishCorrection(userAssessmentId, finalConsiderations);
            if (success) {
                status.finishCorrection = 'success';
                return data;
            }
        }
        catch (error) {
            status.finishCorrection = 'error';
        }
    };
    // eslint-disable-next-line no-unused-vars
    const formatAssessment = (ava) => {
        return {
            userAssessmentId: ava.usuarioAvaliacaoId,
            assessmentTitle: ava.avaliacaoTitulo,
            assessmentObservation: ava.avaliacaoObservacao,
            timeSpentMinutes: ava.tempoGastoEmMinutos,
            score: ava.pontuacao,
            answers: ava.respostas?.sort((a, b) => {
                const orderA = a.questao?.ordem ?? 0;
                const orderB = b.questao?.ordem ?? 0;
                return orderA - orderB;
            }).map(resposta => ({
                id: resposta.id,
                userAssessmentId: resposta.usuarioAvaliacaoId,
                questionId: resposta.questaoId,
                question: resposta.questao ? {
                    id: resposta.questao.id,
                    questionType: resposta.questao.tipoDeQuestao,
                    statement: resposta.questao.enunciado,
                    attachment: resposta.questao.anexo,
                    options: resposta.questao.alternativas?.map(alternativa => ({
                        id: alternativa.id,
                        description: alternativa.descricao,
                        order: alternativa.ordem,
                        isCorrect: alternativa.correta,
                        questionId: alternativa.questaoId,
                    })) || [],
                    scale: resposta.questao.escala,
                    assessmentId: resposta.questao.avaliacaoId,
                    showFeedback: resposta.questao.exibirFeedback,
                    correctFeedback: resposta.questao.feedbackAcerto,
                    incorrectFeedback: resposta.questao.feedbackErro,
                    order: resposta.questao.ordem,
                    image: resposta.questao.imagem ? {
                        mime: resposta.questao.imagem.mime,
                        data: resposta.questao.imagem.data,
                    } : null,
                    answerType: resposta.questao.tipoResposta,
                    maxScore: resposta.questao.pontuacaoMaxima,
                    uploadType: resposta.questao.tipoUpload ? {
                        id: resposta.questao.tipoUpload.id,
                        questionId: resposta.questao.tipoUpload.questaoId,
                        document: resposta.questao.tipoUpload.documentos,
                        audio: resposta.questao.tipoUpload.audio,
                        microphone: resposta.questao.tipoUpload.microfone,
                        screen: resposta.questao.tipoUpload.tela,
                        video: resposta.questao.tipoUpload.video,
                        image: resposta.questao.tipoUpload.imagens,
                        camera: resposta.questao.tipoUpload.camera,
                    } : null,
                    response: resposta.questao.resposta ? {
                        userAssessmentResponseId: resposta.questao.resposta.usuarioAvaliacaoRespostaId,
                        correctAlternativesIds: resposta.questao.resposta.alternativasCorretasIds,
                        answeredAlternativesIds: resposta.questao.resposta.alternativasRespondidasIds,
                        correctFeedback: resposta.questao.resposta.feedbackAcerto,
                        incorrectFeedback: resposta.questao.resposta.feedbackErro,
                        correct: resposta.questao.resposta.acertou,
                        partiallyCorrect: resposta.questao.resposta.acertouParcial,
                        text: resposta.questao.resposta.texto,
                        scale: resposta.questao.resposta.escala,
                        link: resposta.questao.resposta.link,
                        uploads: resposta.questao.resposta.uploads?.map(upload => ({
                            id: upload.id,
                            userAssessmentResponseId: upload.usuarioAvaliacaoRespostaId,
                            link: upload.link,
                            uploadType: upload.tipoUpload,
                        })) || [],
                    } : null,
                } : null,
                text: resposta.texto,
                scale: resposta.escala,
                uploads: resposta.uploads?.map(upload => ({
                    id: upload.id,
                    userAssessmentResponseId: upload.usuarioAvaliacaoRespostaId,
                    link: upload.link,
                    uploadType: upload.tipoUpload,
                })) || [],
                evaluatorCorrection: {
                    id: resposta.avaliadorCorrecao?.id || null,
                    userAssessmentResponseId: resposta.avaliadorCorrecao?.usuarioAvaliacaoRespostaId || null,
                    userId: resposta.avaliadorCorrecao?.usuarioId || null,
                    observations: resposta.avaliadorCorrecao?.observacoes || null,
                    uploads: resposta.avaliadorCorrecao?.uploads?.map(upload => ({
                        id: upload.id,
                        evaluatorCorrectionId: upload.avaliadorCorrecaoId,
                        link: upload.link,
                        uploadType: upload.tipoUpload,
                    })) || [],
                },
                questionStatus: ['Objetiva', 'Multipla', 'VerdadeiroFalso'].includes(resposta.questao.tipoDeQuestao)
                    ? 'submitted' : resposta.avaliadorCorrecao?.usuarioAvaliacaoRespostaId
                    ? 'submitted' : 'pending',
                observations: resposta.avaliadorCorrecao?.observacoes || null,
                score: resposta.pontuacao,
                alternatives: resposta.alternativas?.map(alternativa => ({
                    id: alternativa.id,
                    userAssessmentResponseId: alternativa.usuarioAvaliacaoRespostaId,
                    alternativeId: alternativa.alternativaId,
                    isCorrect: alternativa.correta,
                })) || [],
                link: resposta.link,
            })) || [],
            finalConsiderations: ava.consideracoesFinais,
            status: ava.status,
        };
    };
    return {
        list,
        listAssessmentsStudent,
        listAssessmentsEvaluator,
        listAssessmentsEvaluatorItems,
        loading,
        status,
        methodStatus,
        //
        clear,
        getAll,
        getById,
        save,
        remove,
        bonds,
        prepare,
        getAssessmentsStudent,
        getAssessmentsEvaluator,
        getAssessmentsEvaluatorItems,
        getStudentAssessmentForCorrection,
        sendCorrectionAnswer,
        alterCorrectionAnswer,
        finishCorrection,
    };
});
