import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelText as _vModelText, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "mr-2"
};
const _hoisted_2 = ["type", "placeholder", "maxlength", "disabled", "data-maska", "data-maska-tokens", "data-maska-reversed"];
const _hoisted_3 = ["placeholder", "disabled", "rows"];
const _hoisted_4 = ["placeholder", "maxlength", "disabled"];
const _hoisted_5 = {
    key: 5,
    class: "ui-suffix-icon"
};
const _hoisted_6 = {
    key: 6,
    class: "text-neutral-500 absolute -bottom-0.5 right-1 text-sm"
};
import { computed, inject, ref, useSlots } from 'vue';
import UiIcon from './icon.vue';
export default /*@__PURE__*/ _defineComponent({
    ...{
        inheritAttrs: false
    },
    __name: 'input',
    props: {
        modelValue: {},
        placeholder: {},
        prefix: {},
        suffix: {},
        type: {},
        textTransform: {},
        disabled: { type: Boolean },
        maxlength: {},
        simple: { type: Boolean },
        rows: {},
        error: {},
        mask: {},
        maskTokens: {},
        maskReversed: { type: Boolean },
        togglePassword: { type: Boolean },
        size: {},
        containerClass: {}
    },
    emits: ['update:modelValue'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const slots = useSlots();
        const emit = __emit;
        const isDisabled = inject('form-disabled', false);
        const showPassword = ref(false);
        const isShowingPassword = computed(() => !props.togglePassword ? props.type : (showPassword.value ? 'text' : 'password'));
        // const currentLength = computed(() => `${String(props.modelValue).length}`)
        const validation = inject('validation', {});
        const fcProp = inject('fc-prop', '');
        const value = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val)
        });
        const hasPrefix = computed(() => !!slots.prefix || !!props.prefix || false);
        const hasSuffix = computed(() => !!slots.suffix || !!props.suffix || false);
        const cMask = computed(() => (Array.isArray(props.mask) ? JSON.stringify(props.mask) : props.mask));
        return (_ctx, _cache) => {
            const _directive_maska = _resolveDirective("maska");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(['flex items-center w-full relative', { error: _unref(validation)?.[_unref(fcProp)]?.$invalid }, _ctx.containerClass])
            }, [
                (hasPrefix.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _renderSlot(_ctx.$slots, "prefix")
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.type !== 'textarea' && _ctx.type !== 'decimal')
                    ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                        key: 1,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((value).value = $event)),
                        type: isShowingPassword.value,
                        placeholder: _ctx.placeholder,
                        maxlength: _ctx.maxlength,
                        disabled: (_unref(isDisabled) || _ctx.disabled),
                        "data-maska": cMask.value,
                        "data-maska-tokens": _ctx.maskTokens,
                        "data-maska-reversed": _ctx.maskReversed,
                        class: ['ui-input peer', {
                                'px-2 py-1': _ctx.size === 'small',
                                'px-3 py-3': !_ctx.size || _ctx.size === 'medium',
                                'px-4 py-5': _ctx.size === 'large',
                                'border-l-0 border-t-0 border-r-0 border-b border-neutral-400/50': _ctx.simple
                            }]
                    }, _ctx.$attrs), null, 16, _hoisted_2)), [
                        [
                            _vModelDynamic,
                            value.value,
                            void 0,
                            { trim: true }
                        ],
                        [_directive_maska]
                    ])
                    : _createCommentVNode("", true),
                (_ctx.type === 'textarea')
                    ? _withDirectives((_openBlock(), _createElementBlock("textarea", _mergeProps({
                        key: 2,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((value).value = $event)),
                        placeholder: _ctx.placeholder,
                        disabled: (_unref(isDisabled) || _ctx.disabled),
                        rows: _ctx.rows,
                        class: "ui-input peer h-full grow resize-none"
                    }, _ctx.$attrs), null, 16, _hoisted_3)), [
                        [_vModelText, value.value]
                    ])
                    : _createCommentVNode("", true),
                (_ctx.type === 'decimal')
                    ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                        key: 3,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((value).value = $event)),
                        type: "number",
                        step: 0.1,
                        inputmode: "decimal",
                        placeholder: _ctx.placeholder,
                        maxlength: _ctx.maxlength,
                        disabled: (_unref(isDisabled) || _ctx.disabled),
                        class: ['ui-input peer', {
                                'px-2 py-1': _ctx.size === 'small',
                                'px-3 py-3': !_ctx.size || _ctx.size === 'medium',
                                'px-4 py-5': _ctx.size === 'large',
                                'border-l-0 border-t-0 border-r-0 border-b border-neutral-400/50': _ctx.simple
                            }]
                    }, _ctx.$attrs), null, 16, _hoisted_4)), [
                        [_vModelText, value.value]
                    ])
                    : _createCommentVNode("", true),
                (_ctx.togglePassword)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 4,
                        class: "ui-input-eye ui-suffix-icon cursor-pointer",
                        onClick: _cache[3] || (_cache[3] = ($event) => (showPassword.value = !showPassword.value))
                    }, [
                        _createVNode(UiIcon, {
                            size: 24,
                            class: "text-black dark:text-neutral-400",
                            icon: showPassword.value ? 'eye-off' : 'eye'
                        }, null, 8, ["icon"])
                    ]))
                    : _createCommentVNode("", true),
                (hasSuffix.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _renderSlot(_ctx.$slots, "suffix", { class: "pr-4" })
                    ]))
                    : _createCommentVNode("", true),
                (typeof _ctx.maxlength === 'string' ? (_ctx.maxlength.length > 0) : (_ctx.maxlength > 0))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(`${value.value}`.length) + "/" + _toDisplayString(_ctx.maxlength), 1))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
