import { computed, reactive, readonly, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useAuthStore } from '.';
import { safeCopy, toast, mergeDeep, safeJson, isObject, ObjKeysLcFirst } from '@/common/util';
import webStorage from '@/common/webStorage';
import parametrosService from '@/services/parametros.services';
import { useProgress } from '@/composables/useProgress';
import { config as configModel } from './model/config';
import { termsService } from '@/services/termosDeUso.service';
export const useSystemStore = defineStore('system', () => {
    const i18n = useI18n();
    const clientSettings = useLocalStorage('clientSettings', {
        status: 'initial',
        loadedOnce: false,
        settings: safeCopy(configModel),
        timestamp: null,
        lang: 'pt',
        aad: {
            enabled: false,
            provider: ''
        }
    }, {
        mergeDefaults: true
    });
    const tenantParameters = ref('');
    const langs = [{
            label: 'Português',
            value: 'pt'
        }, {
            label: 'English',
            value: 'en'
        }, {
            label: 'Español',
            value: 'es'
        }];
    const options = useLocalStorage('userOptions', {
        mode: 0,
        dark: 1,
        fullWidth: 1,
        volume: 100,
        autoplay: true
    }, {
        mergeDefaults: true
    });
    const status = ref(0);
    const nStatus = reactive({
        loadParams: 'initial'
    });
    const playing = reactive({ type: null, src: null, trackProgress: false, progress: 0, objData: null });
    const hideMenu = ref(false);
    const tempConfig = ref(safeCopy(configModel));
    const adminConfigLoaded = ref(false);
    const previewMode = ref(false);
    const getConfig = computed(() => (!previewMode.value ? clientSettings.value.settings : tempConfig.value));
    const forClient = computed(() => options.value.mode === 0);
    const loading = computed(() => status.value === 1);
    const tenantIdParameters = computed(() => tenantParameters.value);
    const termsOfUseStatus = ref({
        id: '',
        mode: 'accept',
        show: false,
        content: ''
    });
    const reset = () => Object.assign(tempConfig.value, safeCopy(clientSettings.value.settings));
    const save = async () => {
        const previousValues = safeCopy(clientSettings.value.settings);
        // Cria / Atualiza as novas imagens do carrossel
        for (let i = 0; i < tempConfig.value.login.carousels.length; i++) {
            const { slides } = tempConfig.value.login.carousels[i];
            for (let j = 0; j < slides.length; j++) {
                const { file } = slides[j];
                if (file) {
                    const { data, success } = await parametrosService.dataUrlUpload(file);
                    if (success) {
                        if (file.type.includes('image')) {
                            tempConfig.value.login.carousels[i].slides[j].image = data;
                        }
                        else if (file.type.includes('video')) {
                            const ext = file.name.split('.').pop().toLowerCase();
                            if (['mp4', 'webm', 'avi', 'ogv'].includes(ext)) {
                                tempConfig.value.login.carousels[i].slides[j].video[ext] = data;
                                delete tempConfig.value.login.carousels[i].slides[j].video.url;
                            }
                            else
                                tempConfig.value.login.carousels[i].slides[j].video.url = data;
                        }
                        delete tempConfig.value.login.carousels[i].slides[j].file;
                    }
                }
            }
        }
        const newValues = safeCopy(tempConfig.value);
        // Cria / Atualiza as novas imagens de banners
        for (let i = 0; i < newValues.banners.items.length; i++) {
            const { url, name } = newValues.banners.items[i];
            if (url.substring(0, 10) === 'data:image') {
                const { data, success } = await parametrosService.dataUrlUpload(url, name);
                if (success)
                    newValues.banners.items[i].url = data;
            }
        }
        // Cria / Atualiza as novas imagens de fundo (antigo)
        for (let i = 0; i < newValues.login.backgrounds.length; i++) {
            const imgInfo = newValues.login.backgrounds[i];
            if (imgInfo[0].substring(0, 10) === 'data:image') {
                const { data, success } = await parametrosService.dataUrlUpload(imgInfo[0], imgInfo[1]);
                if (success)
                    newValues.login.backgrounds[i] = [data, data.substring(data.lastIndexOf('/') + 1, data.length)];
            }
        }
        const oldBanner = previousValues.banners.items.map((bannerUrl) => bannerUrl.url);
        const newBanner = newValues.banners.items.map((bannerUrl) => bannerUrl.url);
        // Percorre o array de imagens atuais, para ver quais não existem mais (devido a remoção/update)
        oldBanner.map(async (bannerUrl) => {
            if (!newBanner.includes(bannerUrl))
                await parametrosService.deleteImg(bannerUrl.substring(bannerUrl.lastIndexOf('/') + 1, bannerUrl.length));
        });
        const oldBGImgs = previousValues.login.backgrounds.map((bg) => bg[0]);
        const newBGImgs = newValues.login.backgrounds.map((bg) => bg[0]);
        // Percorre o array de imagens atuais, para ver quais não existem mais (devido a remoção/update)
        oldBGImgs.map(async (bg) => {
            if (!newBGImgs.includes(bg))
                await parametrosService.deleteImg(bg.substring(bg.lastIndexOf('/') + 1, bg.length));
        });
        // Imagens dos slides do carrossel
        const oldSlideImages = previousValues.login.carousels.map((carousel) => carousel.slides.map((slide) => slide.image));
        const newSlideImages = newValues.login.carousels.map((carousel) => carousel.slides.map((slide) => slide.image));
        oldSlideImages.map(async (carousel) => {
            carousel.map(async (img) => {
                if (!newSlideImages.flat().includes(img))
                    await parametrosService.deleteImg(img.substring(img.lastIndexOf('/') + 1, img.length));
            });
        });
        // Videos dos slides do carrossel
        const oldSlideVideos = previousValues.login.carousels.map((carousel) => carousel.slides.map((slide) => slide.video && (slide.video.url || slide.video.mp4 || slide.video.webm || slide.video.ogv || slide.video.avi)));
        const newSlideVideos = newValues.login.carousels.map((carousel) => carousel.slides.map((slide) => slide.video && (slide.video.url || slide.video.mp4 || slide.video.webm || slide.video.ogv || slide.video.avi)));
        oldSlideVideos.map(async (carousel) => {
            carousel.map(async (video) => {
                if (!newSlideVideos.flat().includes(video))
                    await parametrosService.deleteImg(video.substring(video.lastIndexOf('/') + 1, video.length));
            });
        });
        // Verifica se houve alteração dos termos de uso
        const updatedTerms = newValues.general.termOfUse !== previousValues.general.termOfUse;
        Object.assign(tempConfig.value, safeCopy(newValues));
        Object.assign(clientSettings.value.settings, safeCopy(tempConfig.value));
        newValues.login.backgrounds = newBGImgs; // O endpoint aguarda array de strings apenas
        // Não precisa propriedades padrões do sistema
        delete newValues.defaultScripts;
        // Não podemos enviar SSOConfig para o endpoint de atualização
        // @ts-ignore
        delete newValues.ssoConfig;
        // Arrumando a bagunça
        status.value = 1;
        try {
            parametrosService.silent(true);
            const { success } = await parametrosService.update({
                ...newValues
            });
            if (success) {
                toast.success({
                    title: i18n.t('feedback.success'),
                    message: i18n.t('toasts.system.saveSuccess')
                });
                if (updatedTerms) {
                    useAuthStore().resetUserTermsOfUse();
                    if (newValues.general.termOfUse) {
                        checkUserTermsAgreement();
                    }
                }
            }
            status.value = 2;
        }
        catch (error) {
            status.value = -1;
        }
    };
    const loadParams = async (asAdmin) => {
        if (nStatus.loadParams === 'loading')
            return;
        const host = window.location.host;
        const isPlantar = host.includes('plantareducacao.com.br');
        nStatus.loadParams = 'loading';
        try {
            useProgress().start();
            clientSettings.value.status = 'loading';
            const publicRoute = asAdmin || !useAuthStore().loggedIn;
            const { success, data } = await (!publicRoute
                ? parametrosService.get()
                : parametrosService.clientSettings({
                    subDomain: isPlantar ? host.split('.')[0] : ' ',
                    clientUrl: isPlantar ? ' ' : host
                }));
            if (success) {
                if (data && data?.tenantId) {
                    tenantParameters.value = data.tenantId;
                }
                // Normaliza o resultado
                let remoteSettings = safeJson(data?.jsonConfigParams) || data || {};
                if (publicRoute)
                    remoteSettings = ObjKeysLcFirst(remoteSettings);
                // Limpa recursivamente os atributos que são vazios (desse endpoint, ex: data.colors = null)
                const cleanDeep = (obj) => {
                    if (obj === null || !isObject(obj))
                        return;
                    Object.keys(obj).forEach((key) => {
                        if (obj[key] && typeof obj[key] === 'object')
                            cleanDeep(obj[key]);
                        else if (obj[key] === null)
                            delete obj[key];
                    });
                };
                cleanDeep(remoteSettings);
                const mergedSettings = mergeDeep(safeCopy(configModel), remoteSettings);
                if (mergedSettings.login?.backgrounds.length) {
                    mergedSettings.login.backgrounds = mergedSettings.login.backgrounds.map((bg) => {
                        return typeof bg === 'string' ? [bg, bg.split('/').pop()] : bg;
                    });
                }
                Object.assign(clientSettings.value.settings, safeCopy(mergedSettings));
                Object.assign(tempConfig.value, safeCopy(mergedSettings));
                clientSettings.value.aad = {
                    enabled: data?.ssoConfig?.enableSso || false,
                    provider: data?.ssoConfig?.provider || ''
                };
                adminConfigLoaded.value = true;
                nStatus.loadParams = 'success';
            }
        }
        catch (error) {
            nStatus.loadParams = 'error';
            throw new Error(error);
        }
        finally {
            clientSettings.value.status = 'complete';
            clientSettings.value.loadedOnce = true;
            useProgress().done();
        }
    };
    const resetConfig = () => {
        Object.assign(tempConfig.value, safeCopy(configModel));
        Object.assign(clientSettings.value.settings, safeCopy(configModel));
    };
    const setAdminMode = () => (options.value.mode = 1);
    const setClientMode = () => (options.value.mode = 0);
    const toggleTheme = () => (options.value.dark = 1 - options.value.dark);
    const toggleWidth = () => (options.value.fullWidth = 1 - options.value.fullWidth);
    const setVolume = (value) => (options.value.volume = value);
    const play = (props) => Object.assign(playing, { ...props });
    const stopPlaying = () => Object.assign(playing, { id: null, type: null, src: null, trackProgress: false, progress: 0, title: null, objData: null });
    const isDark = computed(() => options.value.dark === 1 ||
        (options.value.dark === 2 && window.matchMedia('(prefers-color-scheme: dark)').matches));
    const setLanguage = (lang) => {
        lang = lang || clientSettings.value.lang;
        clientSettings.value.lang = lang;
        i18n.locale.value = lang;
    };
    const checkUserTermsAgreement = () => {
        const { contract: inContract } = webStorage.getItem('client-mode') || {};
        if (useAuthStore().mustAcceptTermsOfUse && !inContract) {
            termsOfUseStatus.value = {
                id: getConfig.value.general.termOfUse,
                mode: 'accept',
                show: true
            };
        }
    };
    // Condicional para abrir a janela de termos de uso automáticamente
    watch(() => useAuthStore().mustAcceptTermsOfUse, (value) => {
        if (value)
            checkUserTermsAgreement();
    }, { immediate: true });
    const loadTermsOfUse = async (id) => {
        return termsService.getById(id || termsOfUseStatus.value.id)
            .then(({ data, success }) => {
            if (!success)
                return;
            termsOfUseStatus.value.content = data.conteudo;
        })
            .catch((error) => {
            throw new Error(error);
        });
    };
    return {
        availableLangs: langs,
        nStatus,
        clientSettings,
        tempConfig,
        previewMode,
        options,
        playing,
        hideMenu,
        adminConfigLoaded,
        viewTermsOfUse: readonly(termsOfUseStatus),
        // Getters
        getConfig,
        forClient,
        isDark,
        loading,
        tenantIdParameters,
        // Actions
        reset,
        save,
        loadParams,
        loadTermsOfUse,
        resetConfig,
        setAdminMode,
        setClientMode,
        setLanguage,
        toggleTheme,
        toggleWidth,
        setVolume,
        play,
        stopPlaying,
        openTermsOfUse: (id, mode, content) => {
            const newId = id || getConfig.value.general.termOfUse;
            if (content) {
                termsOfUseStatus.value.content = content;
            }
            else if (termsOfUseStatus.value.id !== newId) {
                termsOfUseStatus.value.content = '';
            }
            termsOfUseStatus.value.id = newId;
            termsOfUseStatus.value.mode = mode || 'preview';
            termsOfUseStatus.value.show = true;
        },
        closeTermsOfUse: () => (termsOfUseStatus.value.show = false),
        checkUserTermsAgreement
    };
});
export default useSystemStore;
