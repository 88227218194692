import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue";
const _hoisted_1 = { key: 1 };
const _hoisted_2 = { class: "box-border w-full duration-200 bg-amber-200 dark:bg-amber-800 text-neutral-700 dark:text-amber-200 py-2 px-4 flex items-center justify-between" };
const _hoisted_3 = { class: "text-amber-700 dark:text-amber-300" };
const _hoisted_4 = { class: "font-sm font-semibold" };
const _hoisted_5 = {
    id: "main-content",
    class: "relative box-border h-full w-full p-6"
};
const _hoisted_6 = {
    key: 1,
    class: "relative box-border h-full min-h-screen overflow-hidden bg-neutral-100 text-neutral-700 dark:bg-neutral-700 dark:text-neutral-300"
};
import { computed, onMounted, ref, watch, nextTick, provide, defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useRouteHash } from '@vueuse/router';
import { useStorage, useWindowSize, onClickOutside } from '@vueuse/core';
import { useSystemStore, useAuthStore, useComunicacoesStore, useContratosStore, useSecoesStore } from '@/stores';
import webStorage from '@/common/webStorage';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
import GameBar from '@/components/gamification/sidebar.vue';
import ModalPlayer from '@/components/player/modal.vue';
import Sidebar from './components/sidebar.vue';
import Topbar from './components/topbar.vue';
const threshold = 100;
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup(__props) {
        const FocusTopBar = defineAsyncComponent(() => import('../focus/components/topbar.vue'));
        const { width } = useWindowSize();
        const TermsAgreementModal = defineAsyncComponent(() => import('@/components/modais/usuario/modalAceiteTermoDeUso.vue'));
        const focusMode = ref(false);
        const systemStore = useSystemStore();
        const main = ref();
        const sidebarRef = ref();
        const modalPlayer = ref();
        let startX = 0;
        let dist = 0;
        const route = useRoute();
        const router = useRouter();
        const hash = useRouteHash();
        const sidebar = useStorage('sideBar', {
            minimize: false,
            offScreen: false
        });
        const topbar = ref({
            show: true,
            height: 70
        });
        provide('topbarCfg', topbar);
        const sidebarMaxWidth = computed(() => Number(route.meta?.sidebarMaxWidth) ?? 260);
        const isMobile = computed(() => width.value < 768);
        const minimized = computed(() => isMobile.value ? false : (sidebar.value.minimize));
        watch(() => systemStore.playing.src, (nVal) => {
            if (nVal)
                nextTick(() => modalPlayer.value?.open());
        });
        const exitClientMode = async () => {
            try {
                await useAuthStore()?.exitClientMode();
                await systemStore.loadParams();
                await useAuthStore().getUserInfo();
                router.push('/conta/contrato');
            }
            catch (error) {
                //
            }
        };
        onClickOutside(sidebarRef, () => {
            if (width.value <= 768) {
                sidebar.value.offScreen = true;
            }
        });
        const onMinimize = () => {
            if (width.value <= 768) {
                sidebar.value.offScreen = true;
            }
            else {
                sidebar.value.minimize = !sidebar.value.minimize;
            }
        };
        const stopPlaying = () => {
            systemStore.stopPlaying();
            hash.value = '';
        };
        /**
         * @FIXME Posteriormente melhorar essa lógica para não chamar
         * useSecoesStore nesse componente de layout, mas sim no homeIndex
         */
        const setLocalProgress = ({ progress, id }) => {
            useSecoesStore().updateMedia({ itemId: id, progress });
        };
        const onTouchStart = (e) => {
            startX = e.touches[0].clientX;
            dist = 0;
        };
        const onTouchMove = (e) => {
            if (startX && startX < e.touches[0].clientX && startX < 200) {
                dist = e.touches[0].clientX - startX;
            }
        };
        const onTouchEnd = () => {
            if (dist > threshold) {
                sidebar.value.offScreen = !sidebar.value.offScreen;
            }
        };
        onMounted(() => {
            try {
                const showCommunication = webStorage.getItem('showCommunication');
                nextTick(() => {
                    if (showCommunication && !useAuthStore()?.mustAcceptTermsOfUse && useComunicacoesStore()?.getUserCommunication()) {
                        useContratosStore()?.getIsDistribuidor();
                    }
                    systemStore.checkUserTermsAgreement();
                });
            }
            catch (error) {
                //
            }
        });
        return (_ctx, _cache) => {
            const _component_RouterView = _resolveComponent("RouterView");
            return (!focusMode.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    ref_key: "main",
                    ref: main,
                    class: "relative box-border flex h-full min-h-screen overflow-hidden bg-neutral-100 text-neutral-700 dark:bg-neutral-700 dark:text-neutral-300",
                    onTouchstart: onTouchStart,
                    onTouchmove: onTouchMove,
                    onTouchend: onTouchEnd
                }, [
                    _createVNode(_Transition, {
                        mode: "out-in",
                        "enter-active-class": "ease-out duration-150",
                        "enter-from-class": "opacity-0 -translate-x-8",
                        "leave-active-class": "ease-in duration-150",
                        "leave-to-class": "opacity-0 -translate-x-8"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(Sidebar, {
                                ref_key: "sidebarRef",
                                ref: sidebarRef,
                                minimized: minimized.value,
                                class: _normalizeClass(["fixed z-[11] auto-cols-min transition-all", { 'shadow-2xl md:shadow-none': !_unref(sidebar).offScreen }]),
                                "max-width": (isMobile.value && !_unref(sidebar).offScreen ? '100%' : (sidebarMaxWidth.value || 260)),
                                style: _normalizeStyle({ left: isMobile.value && _unref(sidebar).offScreen ? `-${sidebarMaxWidth.value || 260}px` : 0 }),
                                onOnMinimize: onMinimize
                            }, null, 8, ["minimized", "class", "max-width", "style"])
                        ]),
                        _: 1
                    }),
                    _createElementVNode("div", {
                        class: "relative box-border min-h-full w-full max-w-[100vw] transition-[padding-left] duration-200",
                        style: _normalizeStyle({
                            paddingLeft: isMobile.value ? 0 : `${!_unref(sidebar).minimize ? (sidebarMaxWidth.value || 260) : 80}px`,
                            paddingTop: `${topbar.value.show ? topbar.value.height : 0}px`
                        })
                    }, [
                        (topbar.value.show)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "fixed left-0 top-0 z-10 box-border w-full transition-[padding-left] duration-200",
                                style: _normalizeStyle({ paddingLeft: isMobile.value ? 0 : `${!_unref(sidebar).minimize ? (sidebarMaxWidth.value || 260) : 80}px` })
                            }, [
                                _createVNode(Topbar, {
                                    style: _normalizeStyle({ minHeight: `${topbar.value.height}px` })
                                }, null, 8, ["style"])
                            ], 4))
                            : _createCommentVNode("", true),
                        (_unref(useAuthStore)()?.clientMode.contract)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("div", null, [
                                        _createElementVNode("span", _hoisted_3, [
                                            _createVNode(UiIcon, {
                                                icon: "alert-triangle",
                                                size: "18",
                                                "stroke-width": 2
                                            })
                                        ]),
                                        _cache[2] || (_cache[2] = _createTextVNode(" Modo cliente: ")),
                                        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(useAuthStore)()?.clientMode.contract), 1)
                                    ]),
                                    _createVNode(UiButton, {
                                        type: "info",
                                        class: "p-2 flex items-center",
                                        onClick: _cache[0] || (_cache[0] = ($event) => (exitClientMode()))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiIcon, {
                                                icon: "door-exit",
                                                size: "16",
                                                "stroke-width": 2,
                                                class: "mr-1 -scale-x-100"
                                            }),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('button.exitContract')), 1)
                                        ]),
                                        _: 1
                                    })
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_RouterView, null, {
                                default: _withCtx(({ Component }) => [
                                    _createVNode(_Transition, {
                                        mode: "out-in",
                                        "enter-active-class": "ease-out duration-150",
                                        "enter-from-class": "opacity-20 -translate-y-8",
                                        "leave-active-class": "ease-in duration-150",
                                        "leave-to-class": "opacity-20 -translate-y-8"
                                    }, {
                                        default: _withCtx(() => [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { class: "h-full" }))
                                        ]),
                                        _: 2
                                    }, 1024)
                                ]),
                                _: 1
                            })
                        ])
                    ], 4),
                    (_unref(systemStore).playing.src)
                        ? (_openBlock(), _createBlock(ModalPlayer, {
                            key: 0,
                            ref_key: "modalPlayer",
                            ref: modalPlayer,
                            mediaId: _unref(systemStore).playing?.mediaId,
                            src: _unref(systemStore).playing.src,
                            type: _unref(systemStore).playing?.type,
                            progress: _unref(systemStore).playing?.progress ?? 0,
                            "track-progress": _unref(systemStore).playing?.trackProgress,
                            title: _unref(systemStore).playing.title,
                            "obj-data": _unref(systemStore).playing.objData,
                            "allow-download": _unref(systemStore).playing.allowDownload,
                            onOnClose: _cache[1] || (_cache[1] = () => stopPlaying()),
                            onOnRegisterProgress: setLocalProgress
                        }, null, 8, ["mediaId", "src", "type", "progress", "track-progress", "title", "obj-data", "allow-download"]))
                        : _createCommentVNode("", true),
                    (_unref(systemStore) && _unref(systemStore)?.viewTermsOfUse.id)
                        ? (_openBlock(), _createBlock(_unref(TermsAgreementModal), { key: 1 }))
                        : _createCommentVNode("", true),
                    _createVNode(GameBar)
                ], 544))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_unref(FocusTopBar), {
                        style: _normalizeStyle({ minHeight: `${topbar.value.height}px` })
                    }, null, 8, ["style"]),
                    _createVNode(_component_RouterView, null, {
                        default: _withCtx(({ Component }) => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { class: "h-full p-4" }))
                        ]),
                        _: 1
                    })
                ]));
        };
    }
});
