import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "relative p-3" };
const _hoisted_2 = { class: "flex items-center gap-2" };
const _hoisted_3 = { class: "space-y-2" };
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = {
    key: 0,
    class: "progress"
};
import { computed, onMounted, ref } from 'vue';
import UiIcon from '@/components/ui/icon.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        id: {},
        type: {},
        title: {},
        message: {},
        autoClose: { type: Boolean },
        duration: {},
        icon: {},
        onClick: { type: Function }
    },
    emits: ['close'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const timer = ref(-1);
        const startedAt = ref(0);
        const delay = ref(0);
        onMounted(() => {
            if (props.autoClose) {
                startedAt.value = Date.now();
                delay.value = (props.duration || 5) * 1000;
                timer.value = setTimeout(close, delay.value);
            }
        });
        const toastIcon = computed(() => {
            if (props.icon)
                return props.icon;
            switch (props.type) {
                case 'error':
                    return 'error';
                case 'warning':
                    return 'warning';
                case 'success':
                    return 'check_circle';
                default:
                    return 'info';
            }
        });
        const toastType = computed(() => {
            const tp = props.type.toLocaleLowerCase();
            const opts = ['error', 'warning', 'success', 'info', 'default'];
            return `t-${opts.includes(tp) ? tp : 'default'}`;
        });
        const execClick = () => {
            if (props.onClick && typeof props.onClick === 'function') {
                props.onClick();
            }
        };
        const close = () => {
            emit('close');
        };
        return (_ctx, _cache) => {
            const _component_pe_icon = _resolveComponent("pe-icon");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["toast-container", toastType.value]),
                style: _normalizeStyle(`--toast-duration: ${_ctx.duration}s;`),
                ref: _ctx.id,
                onClick: _cache[1] || (_cache[1] = () => execClick())
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("button", {
                        class: "absolute top-0 right-0 cursor-pointer p-2 hover:opacity-60",
                        title: "Close",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => close(), ["stop"]))
                    }, [
                        _createVNode(UiIcon, { icon: "x" })
                    ]),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_pe_icon, { size: 24 }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(toastIcon.value), 1)
                            ]),
                            _: 1
                        }),
                        _createElementVNode("div", _hoisted_3, [
                            (_ctx.title !== null)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: "font-semibold",
                                    innerHTML: _ctx.title || 'Atenção'
                                }, null, 8, _hoisted_4))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", { innerHTML: _ctx.message }, null, 8, _hoisted_5)
                        ])
                    ]),
                    (_ctx.autoClose)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                        : _createCommentVNode("", true)
                ])
            ], 6));
        };
    }
});
