import BaseService from './base.service';
import { useAuthStore } from '@/stores';
class AutenticacaoService extends BaseService {
    formData;
    constructor() {
        super({ resource: 'autenticacao' });
        const { scope } = window.configuration?.aliare?.credentials || {};
        this.formData = {
            scope
        };
    }
    async forgotPassword(email, tenantId) {
        let headers = {};
        if (!tenantId && !useAuthStore().loggedIn) {
            const tenantId = await useAuthStore().getActiveTentant;
            headers = tenantId && tenantId !== window?.configuration?.plantar?.tenant ? { 'x-tenant': tenantId } : {};
        }
        else if (tenantId) {
            headers = { 'x-tenant': tenantId };
        }
        return await this.plantarApi.get(`${this.resource}/forgotpassword/${email}`, { headers });
    }
    async registerLogAuthentication() {
        try {
            return await this.plantarApi.post('AuthenticationLog');
        }
        catch (error) {
            //
        }
        return { success: false };
    }
    async silentRefresh(refreshToken) {
        const payload = {
            ...this.formData,
            grant_type: 'refresh_token',
            refresh_token: refreshToken
        };
        const { data } = await this.plantarApi.post(`${this.resource}/Refresh`, payload);
        const formattedData = {
            access_token: data.access_Token,
            expires_in: data.expires_In,
            refresh_token: data.refresh_Token,
            scope: data.scope,
            token_type: data.token_Type
        };
        return formattedData;
    }
    async login({ username, password }) {
        const payload = {
            username: username.replace(/\s/g, ''),
            password: password
        };
        try {
            const { data } = await this.plantarApi.post(this.resource, payload);
            const formattedData = {
                access_token: data.access_Token,
                expires_in: data.expires_In,
                refresh_token: data.refresh_Token,
                scope: data.scope,
                token_type: data.token_Type
            };
            return formattedData;
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async sendNewPassword(params) {
        try {
            const { data, success } = await this.plantarApi.post(`${this.resource}/CreatePassword`, params);
            return {
                success,
                data
            };
        }
        catch (error) {
            return {
                success: false,
                data: null
            };
        }
    }
}
const autenticacaoService = new AutenticacaoService();
export default autenticacaoService;
