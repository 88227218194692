import { useNProgress } from '@vueuse/integrations/useNProgress';
// import { ref, watch } from 'vue'
export const useProgress = () => {
    const { isLoading, progress, start, done } = useNProgress();
    // const queueProgress = ref([])
    // watch(
    //   () => queueProgress.value,
    //   (value) => {
    //     if (value.length > 0) {
    //       progress.value = 
    //       start()
    //     } else {
    //       done()
    //     }
    //   }
    // )
    return {
        isLoading,
        progress,
        start,
        done
    };
};
