import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "relative min-h-screen" };
const _hoisted_2 = {
    key: 0,
    class: "absolute top-0 left-0 z-50 text-center p-4 flex items-center justify-center w-full h-screen"
};
const _hoisted_3 = { class: "toast-notifications fixed top-2 right-2 z-[9999] flex flex-col-reverse gap-3" };
import { computed, onBeforeMount, provide, ref, watch, watchEffect } from 'vue';
import { useFavicon, useTitle } from '@vueuse/core';
import { ModalsContainer } from 'vue-final-modal';
import tinycolor from 'tinycolor2';
import { useSystemStore, useNotificationsStore } from '@/stores';
import { i18N, toast } from './common/util';
import peToast from '@/components/peToast/index.vue';
import peDialog from '@/components/PeDialog.vue';
import UiDialog from '@/components/ui/dialog.vue';
import UiLoader from '@/components/ui/loader.vue';
import LangSelectorDialog from '@/components/modais/langSelector.vue';
import TransparentLogo from '@/assets/logo_transparent.png';
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup(__props) {
        const systemStore = useSystemStore();
        const notificationStore = useNotificationsStore();
        const systemUpdate = ref({
            hasUpdate: false,
            hash: ''
        });
        const showClassMenu = ref(true);
        const langSelector = ref();
        const openLangSelector = () => langSelector.value?.open();
        provide('app.showClassMenu', showClassMenu);
        provide('app.openLangSelector', openLangSelector);
        provide('app.systemUpdate', systemUpdate);
        // Tema escuro/Claro
        watchEffect(() => {
            document.querySelector('body')?.classList.toggle('dark', !systemStore.isDark);
        });
        useTitle(computed(() => systemStore.getConfig.page?.title || 'Educação Corporativa'));
        useFavicon(computed(() => systemStore.getConfig.logos?.icon || TransparentLogo));
        const colorVariations = [28, 24, 16, 8, 0, -8, -16, -24, -28];
        const primaryShades = computed(() => colorVariations.map((variation) => tinycolor(systemStore.getConfig.colors.interface?.primary || '#9E9E9E')
            .lighten(variation)
            .toHexString()));
        watch(() => systemStore.getConfig.colors.interface, () => {
            const darkestShade = tinycolor(systemStore.getConfig.colors.interface?.primary || '#9E9E9E')
                .darken(50)
                .toHexString();
            primaryShades.value?.forEach((s, idx) => {
                document.documentElement.style.setProperty(`--c-primary-${idx + 1}00`, s);
                // Auto text color
                if (systemStore.getConfig.colors.interface.autoFontColor) {
                    document.documentElement.style.setProperty(`--c-primary-at-${idx + 1}00`, tinycolor(s).isLight() ? darkestShade : '#FDFDFD');
                }
                else {
                    document.documentElement.style.setProperty(`--c-primary-at-${idx + 1}00`, systemStore.getConfig.colors.interface.textColor || '#FDFDFD');
                }
            });
        }, { immediate: true, deep: true });
        const appendScript = (script) => {
            const scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            if (script.content?.trim().length === 0)
                return;
            if (!script.isLink) {
                scriptTag.innerHTML = script.content || '';
            }
            else {
                scriptTag.src = script.content || '';
            }
            document.head.appendChild(scriptTag);
        };
        const checkUpdate = async () => {
            try {
                if (window?.configuration?.env !== 'Production')
                    return;
                let res = await fetch(window.location.origin + `/index.html?t=${new Date().getTime()}`);
                let str = await res.text();
                let strFind = 'src="/app.';
                let start = str.indexOf(strFind);
                let hash = str.substring(start + strFind.length, start + strFind.length + 16);
                let vers = localStorage.getItem('version');
                if (vers && vers !== hash) {
                    systemUpdate.value.hasUpdate = true;
                    systemUpdate.value.hash = hash;
                    toast.open({
                        type: 'warning',
                        title: i18N('feedback.updateAvailable'),
                        message: i18N('feedback.updateAvailableMessage'),
                        autoClose: false,
                        onClick: () => reload()
                    });
                }
                localStorage.setItem('version', hash);
            }
            catch (error) {
                console.log('att', error);
            }
        };
        const reload = () => {
            window.location.reload();
        };
        onBeforeMount(() => {
            systemStore.setLanguage();
            // @FIXME: SXNzbyBhcXVpIMOpIGdhbWJpYXJyYSwgYWNoZSB1bWEgc29sdcOnw6NvIG1lbGhvcg==
            systemStore.clientSettings.settings?.defaultScripts?.map((script) => {
                if (typeof script.condition !== 'undefined' && !script.condition)
                    return;
                appendScript(script);
            });
            systemStore
                .loadParams()
                .then(() => {
                systemStore.clientSettings.settings?.scripts?.map((script) => {
                    appendScript(script);
                });
            })
                .catch(() => { });
            checkUpdate();
        });
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_unref(systemStore).clientSettings.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(UiLoader, { size: "80px" })
                    ]))
                    : _createCommentVNode("", true),
                _createVNode(_component_router_view, null, {
                    default: _withCtx(({ Component }) => [
                        _createVNode(_Transition, {
                            mode: "out-in",
                            appear: "",
                            "enter-active-class": "transition ease-out duration-150 transform",
                            "enter-from-class": "opacity-0 translate-x-4",
                            "enter-to-class": "opacity-100",
                            "leave-active-class": "ease-in duration-200",
                            "leave-from-class": "opacity-100",
                            "leave-to-class": "opacity-0 -translate-x-4"
                        }, {
                            default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                            ]),
                            _: 2
                        }, 1024)
                    ]),
                    _: 1
                }),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_TransitionGroup, {
                        name: "toast-notification",
                        tag: "div",
                        "enter-active-class": "transition ease-out duration-300 transform",
                        "enter-from-class": "opacity-0 -translate-y-10 scale-50",
                        "enter-to-class": "opacity-100 translate-y-0 scale-100",
                        "leave-active-class": "ease-in duration-200",
                        "leave-from-class": "opacity-100 translate-y-0 scale-100",
                        "leave-to-class": "opacity-0 -translate-y-10 translate-y-0 scale-50"
                    }, {
                        default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notificationStore).toasts, (item) => {
                                return (_openBlock(), _createBlock(peToast, {
                                    key: item.id,
                                    id: item.id || '',
                                    type: item.type,
                                    title: item.title,
                                    message: item.message,
                                    icon: item.icon,
                                    "auto-close": item.autoClose,
                                    duration: item.duration,
                                    onClick: item.onClick,
                                    onClose: () => _unref(notificationStore).dismissToast(item.id || ''),
                                    class: "mx-4 mt-4"
                                }, null, 8, ["id", "type", "title", "message", "icon", "auto-close", "duration", "onClick", "onClose"]));
                            }), 128))
                        ]),
                        _: 1
                    })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notificationStore).dialogs, (item) => {
                    return (_openBlock(), _createBlock(peDialog, {
                        key: item.id,
                        auto: "",
                        title: item.title,
                        message: item.message,
                        alt: item.alt,
                        hideImage: item.hideImage,
                        hideCancelButton: item.hideCancelButton,
                        cancelButtonText: item.cancelButtonText,
                        confirmButtonText: item.confirmButtonText,
                        onOnConfirm: () => {
                            _unref(notificationStore).dismissDialog(item.id || '');
                            if (typeof item.complete === 'function')
                                item.complete();
                        },
                        onOnCancel: () => {
                            _unref(notificationStore).dismissDialog(item.id || '');
                            if (typeof item.cancel === 'function')
                                item.cancel();
                        }
                    }, null, 8, ["title", "message", "alt", "hideImage", "hideCancelButton", "cancelButtonText", "confirmButtonText", "onOnConfirm", "onOnCancel"]));
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notificationStore).newDialogs, (item) => {
                    return (_openBlock(), _createBlock(UiDialog, {
                        key: item.id,
                        title: item.title,
                        message: item.message,
                        id: item.id,
                        complete: item.complete,
                        cancel: item.cancel,
                        "type-confirm-button": item.typeConfirmButton,
                        "text-confirm-button": item.textConfirmButton,
                        immediate: item.immediate ?? true
                    }, null, 8, ["title", "message", "id", "complete", "cancel", "type-confirm-button", "text-confirm-button", "immediate"]));
                }), 128)),
                _createVNode(_unref(ModalsContainer)),
                _createVNode(LangSelectorDialog, {
                    ref_key: "langSelector",
                    ref: langSelector
                }, null, 512)
            ]));
        };
    }
});
