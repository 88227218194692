import { ref, nextTick } from 'vue';
import { useNotificationsStore, useGamificacaoStore } from '@/stores';
import { useModal } from 'vue-final-modal';
import { utils, writeFileXLSX } from 'xlsx';
import i18n from '@/plugins/i18n';
const { t } = i18n.global;
import ModalAchievement from '@/components/ui/gamification/completation.vue';
import { computed } from 'vue';
const namePrepositions = ['da', 'de', 'do', 'das', 'dos'];
export const getLanguage = () => navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
export const toast = {
    open: (opts) => {
        const notificationStore = useNotificationsStore();
        return notificationStore.addToast(opts);
    },
    dismiss: (id) => {
        const notificationStore = useNotificationsStore();
        notificationStore.dismissToast(id);
    },
    success: (opts) => toast.open(Object.assign({ title: t('feedback.success'), type: 'success' }, opts)),
    error: (opts) => toast.open(Object.assign({ title: t('feedback.error'), type: 'error' }, opts))
};
export const dialog = {
    open: (obj) => {
        const notificationStore = useNotificationsStore();
        notificationStore.addDialog(obj);
    },
    dismiss: (id) => {
        const notificationStore = useNotificationsStore();
        notificationStore.dismissDialog(id);
    }
};
export const newDialog = {
    open: (obj) => {
        const notificationStore = useNotificationsStore();
        notificationStore.addNewDialog(obj);
    },
    dismiss: (id) => {
        const notificationStore = useNotificationsStore();
        notificationStore.dismissNewDialog(id);
    }
};
export const openAchievement = (opts) => {
    const { open, close } = useModal({
        component: ModalAchievement,
        attrs: {
            title: opts.title,
            subtitle: opts.subtitle,
            points: opts.points,
            onConfirm() {
                close();
                if (!opts.ignoreUpdate) {
                    useGamificacaoStore().getMyRank();
                    useGamificacaoStore().resetList('all');
                }
            }
        }
    });
    setTimeout(() => {
        open();
    }, 200);
};
export const isValidCPF = (value) => {
    if (typeof value !== 'string')
        return false;
    value = value.replace(/[^\d]+/g, '');
    if (value.length !== 11 || !!value.match(/(\d)\1{10}/))
        return false;
    const digits = value.split('').map((el) => +el);
    const getVerifyingDigit = (arr) => {
        const reduced = arr.reduce((sum, digit, index) => sum + digit * (arr.length - index + 1), 0);
        return ((reduced * 10) % 11) % 10;
    };
    return (getVerifyingDigit(digits.slice(0, 9)) === digits[9] &&
        getVerifyingDigit(digits.slice(0, 10)) === digits[10]);
};
export const formatter = {
    cpfCnpj: (texto) => {
        texto = (texto || '').replace(/[^\d]/g, '');
        if (texto.length === 11)
            texto = texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        else if (texto.length === 14)
            texto = texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
        return texto;
    },
    date: (dt) => {
        if (!dt)
            return null;
        const dateString = dt.toString().split('T')[0];
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    },
    typePeople: (type) => ({
        juridica: t('labels.legalEntity'),
        fisica: t('labels.naturalPerson'),
        estrangeira: t('labels.foreign')
    })[type?.toLowerCase()] ?? '',
    numberPhonePtBr: (phone) => phone
        .replace(/[^0-9]/g, '')
        .slice(0, 14)
        .replace(/^([0-9]{1,2})([0-9]{2,6})([0-9]{4})$/, '+55($1)$2-$3'),
    typeClassificacaoCliente: (type) => ({
        embarcado: t('labels.embedded'),
        plantar: 'Plantar',
        parceiro: t('labels.partner'),
        termoadesao: t('labels.adhesionAgreement'),
        experimentacao: t('labels.experimentation'),
        didactica: t('labels.didactica')
    })[type?.toLowerCase()] || type,
    typeProdutoAliare: (type) => ({
        crmclover: 'CRM Clover',
        erpagribusiness: 'ERP AgriBusiness',
        erpagrimanager: 'ERP AgriManager',
        erpsimer: 'ERP Simer',
        erpsolution: 'ERP Solution',
        vistrabi: 'Vistra BI',
        plantareducacao: 'Plantar Educação',
        erpessencial: 'ERP Essencial'
    })[type?.toLowerCase()] ?? ''
};
export const validateLink = {
    youtube: (value) => /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm.test(value),
    vimeo: (value) => /https?:\/\/(www.)?vimeo.com\/([0-9]{5,8})/g.test(value),
    soundcloud: (value) => /(https?:\/\/(soundcloud\.com|snd\.sc)\/(.*))/g.test(value),
    verify: (value) => validateLink.youtube(value) || validateLink.vimeo(value) || validateLink.soundcloud(value)
};
/**
 * Função que retorna um objeto reativo com controle de reset
 * @param data
 */
export const ctrlRef = (data) => {
    const value = ref(safeCopy(data));
    return { value, reset: () => { value.value = safeCopy(data); } };
};
/**
 * Troca os elementos de duas posições do array
 * @param arr
 * @param idx1
 * @param idx2
 */
export const swapIndex = (arr, idx1, idx2) => {
    const tempData = arr[idx1];
    arr[idx1] = arr[idx2];
    arr[idx2] = tempData;
};
/**
 * Move o eleento de uma posição para outra
 * @param arr
 * @param fromIndex
 * @param toIndex
 */
export const moveIndex = (arr, fromIndex, toIndex) => {
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
};
/**
 * Gera uma copia segura de um objeto
 * @param obj
 */
export const safeCopy = (obj) => JSON.parse(JSON.stringify(obj));
export const safeJson = (obj) => {
    try {
        return JSON.parse(obj);
    }
    catch {
        return false;
    }
};
/**
 * Retorna o um numero respeitando a limitação mínima e máxima
 * @param num
 * @param min
 * @param max
 * @example clamp(300, 15, 150) === 150
 * @example clamp(2, 15, 150) === 15
 */
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
/**
 * Gera uma UUID aleatório
 */
export const generateUUID = () => {
    let d = new Date().getTime();
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16;
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        }
        else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};
/**
 * Formata uma string baseada nos parametros
 * @example '{0}:{1}:{2}'.format(12, '33', 5/2, ...)
 */
export const format = (...args) => {
    const str = args.shift();
    if (!str || typeof str !== 'string')
        return '';
    return str.replace(/{([0-9]+)}/g, (match, index) => typeof args[index] == 'undefined' ? match : args[index]);
};
/**
 * Converte um numero para o formato HH:MM:SS
 * @param time
 * @example convertHMS(300) === '00:05:00'
 */
export const convertHMS = (time) => {
    const hours = (time / 3600) | 0;
    const minutes = ((time - hours * 3600) / 60) | 0;
    const seconds = (time | 0) - hours * 3600 - minutes * 60;
    return [hours, minutes, seconds].map((el) => `${el}`.padStart(2, '0')).join(':');
};
/**
 * Converte horas em HH:mm para HHH:mm e completa dígitos faltantes com '0'
 * @param time
 * @example 12:00 === '012:00'
 * @example 12 === '012:00'
 * @example 12:1 === '012:10'
 */
export const convertHHHmm = (hoursAndMin) => {
    const arr = hoursAndMin.split(':');
    return `${arr[0]}`.padStart(2, '0') + ':' + `${arr[1]}`.padStart(2, '0');
};
/**
 * Remove acentos de uma palavra
 * @param str
 * @example removeAccents('ÀÉÍÓÚ') === 'AEIOU'
 */
export const removeAccents = (str) => (str || '')
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/[\u0300-\u036f]/gi, '');
/**
 * Remove os seguintes caracteres de uma string: ()[]*
 * @param str
 * @returns
 */
export const removeSpecialCharacters = (str) => str
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/[^\w\s]/gi, '');
/**
 * Retorna true se str2 está contida em str1 ignorando maiúsculas e minúsculas e acentos
 * @example strSearch('frase grande ÀÉÍÓÚ', 'aeiou') === true
 */
export const strSearch = (str1, str2) => {
    return (removeAccents(removeSpecialCharacters(str1))
        .toLowerCase()
        .search(removeAccents(removeSpecialCharacters(str2.trim())).toLowerCase()) > -1);
};
/**
 * Função que busca um elemento com base no id dentro de uma árvore de elementos
 * @param tree
 * @param id
 * @returns irá retornar o elemento encontrado ou null
 */
export const findElementTree = (tree, id) => {
    let elementFound = tree.find((el) => el.id === id);
    if (elementFound) {
        return elementFound;
    }
    else {
        tree.forEach((row) => {
            if (row.children.length > 0 && elementFound == null)
                elementFound = findElementTree(row.children, id);
        });
    }
    return elementFound;
};
/**
 * Função que navega pelo router do vue e transforma a árvore de rotas em um array
 * @param tree Árvore de elementos
 * @param result Array gerado com todos os elementos
 * @param parent Elemento pai de uma rota -> @example {parent: Contas, child: Usuários}
 * @returns
 */
export const convertTreeRouterToArr = (tree, result = [], parent) => {
    if (!tree)
        return [];
    tree.forEach((element) => {
        if (element?.meta?.title) {
            const nameParent = parent ? parent?.meta?.title : '';
            result.push({
                redirectName: element.name,
                parent: nameParent,
                name: element.meta.title,
                path: element.path,
                filterPermission: element.meta?.filterPermission ?? false
            });
        }
        if (element.children?.length > 0) {
            convertTreeRouterToArr(element.children, result, element);
        }
    });
    return result;
};
/** Retorna um item aleatório de um array
 * @param arr array alvo
 * @example arrRand(['a', 'b', 'c'])
 */
export const arrRand = (arr) => arr && arr[(Math.random() * arr.length) | 0];
/**
 * Retorna um numero aleatório em um intervalo
 * @param min
 * @param max
 * @example intervalRand(33, 55) // 33 >= resultado <= 55
 */
export const intervalRand = (min, max) => {
    return Math.random() * (max - min) + min;
};
const arrMidiaType = ['course', 'formation', 'trail', 'mediaObject', 'test', 'scorm'];
const arrMidiaTypeAlt = ['curso', 'formacao', 'formacao', 'midia', 'avaliacao', 'scorm'];
const findArrMT = (type) => {
    const first = arrMidiaType.indexOf(type);
    return first > -1 ? first : arrMidiaTypeAlt.indexOf(type);
};
/**
 * Controla a codificação/decodificação da url de aprendizado
 */
export const learnUrl = {
    /**
     * Codifica a url
     * @param str
     */
    encode: (type, id) => btoa(`${findArrMT(type)}${id.split('-').join('')}`),
    /**
     * Decodifica a url
     * @param str
     */
    decode: (str) => {
        const pars = atob(str);
        const [type, id] = [pars[0], pars.substring(1)];
        return {
            type: arrMidiaType[parseInt(type)],
            id: [
                id.substring(0, 8),
                id.substring(8, 12),
                id.substring(12, 16),
                id.substring(16, 20),
                id.substring(20)
            ].join('-')
        };
    }
};
/**
 * Converte um array simples para array de objeto "{ label, value }[]""
 * @param arr
 * @example
 * arrToLabelValue = ['oi', 'vc']
 * // [{ label: 'oi', value: 'oi'}, { label: 'vc', value: 'vc'}]
 */
export const arrToLabelValue = (arr) => arr.map((value) => ({
    label: value,
    value
}));
/**
 * Converte um arquivo base64 para "File"
 * @param dataurl
 * @param filename
 * @example file = dataURLtoFile('data:image/png;base64,......', 'a.png')
 */
export const dataURLtoFile = (dataurl, filename) => {
    if (!dataurl)
        return new File([], filename);
    const arr = (dataurl || '').split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};
/**
 * Manipulador de localStorage
 */
export const storage = {
    get: (key, defaultVal) => JSON.parse(localStorage.getItem(key) || '') || defaultVal,
    set: (key, val) => localStorage.setItem(key, JSON.stringify(val)),
    remove: (key) => localStorage.removeItem(key)
};
/**
 * Retorna uma versão achatada de um objeto
 * @param obj
 * @example
 * const obj = { a: {b: {c: 3} }, d: 'oi' }
 * flatObject(obj)
 * // { 'a.b.c': 3, d: 'oi'}
 */
export const flatObject = (object, parents = []) => Object.assign({}, ...Object.entries(object).map(([k, v]) => v && typeof v === 'object' && !Array.isArray(v)
    ? flatObject(v, [...parents, k])
    : { [[...parents, k].join('.')]: v }));
/**
 * Garante que a primeira letra das chaves de um objeto estão em lowerCase
 * @param input
 * @example
 * const obj = { ABC: 'ABC', Acc: 'aCC', SSS: { AAA: 'SSS.AAA' } }
 * ObjKeysLcFirst(obj)
 * // { aBC: 'ABC', acc: 'aCC', sSS: { aAA: 'SSS.AAA' } }
 */
export const ObjKeysLcFirst = (input) => {
    if (typeof input !== 'object')
        return input;
    if (Array.isArray(input))
        return input.map(ObjKeysLcFirst);
    return Object.keys(input).reduce((newObj, key) => {
        const val = input[key];
        const newVal = typeof val === 'object' && val !== null ? ObjKeysLcFirst(val) : val;
        newObj[key.charAt(0).toLowerCase() + key.slice(1)] = newVal;
        return newObj;
    }, {});
};
/**
 * Verifica se um elemento é um objeto (ignorando array)
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
};
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
    if (!sources.length)
        return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
};
/**
 *
 * @param sizeBytes
 * @param maxSize
 * @param unit
 */
export const validateFileSize = (sizeBytes, maxSize, unit) => {
    const factor = unit === 'MB' ? 1024 * 1024 : 1024;
    if (Number(sizeBytes / factor) > maxSize) {
        toast.error({
            message: t('validation.fileSizeExceeds', { maxSize, unit })
        });
        return false;
    }
    return true;
};
/**
 * Função que retorna a duração em horas e minutos
 * @param time
 * @returns
 */
export const duration = (time) => {
    const hours = (time / 3600) | 0;
    const minutes = ((time - hours * 3600) / 60) | 0;
    return `${hours > 0 ? `${hours}h` : ''} ${`${minutes}`.padStart(2, '0')}min`;
};
/**
 * Função que converte a duração (HHH:mm, HH:mm, HH:mm:SS) em segundos
 * @param duration
 * @returns
 */
export const convertTime = (duration) => {
    const arrDuration = (duration || '').split(':');
    if (arrDuration.length === 1)
        return parseInt(arrDuration[0]);
    else if (arrDuration.length === 2)
        return parseInt(arrDuration[1]) + (60 * +arrDuration[0]);
    return parseInt(arrDuration?.[2] || '00') + 60 * +arrDuration[1] + 60 * (60 * +arrDuration[0]);
};
/**
 * Função que formata a duração (HHH:mm:ss, HH:mm:ss) para o formato 99h 99min 99s
 * @param duration string
 * @param secondsType 'truncate' | 'hide'
 * @param fallback valor padrão caso não tenha duração
 * @returns string
 * @example fmtDuration('00:14:30') -> '14min 30s'
 * @example fmtDuration('00:00:27, 'truncate') -> '< 1min'
 */
export const fmtDuration = (duration, secondsType, fallback) => {
    let [hours, minutes, seconds] = typeof duration === 'string' ? duration.split(':').map((t) => Number(t)) : [0, 0, Number(duration)];
    if (seconds > 59) {
        minutes += Math.floor(seconds / 60);
        seconds = seconds % 60;
    }
    if (minutes > 59) {
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
    }
    const result = [];
    if (hours > 0)
        result.push(`${hours}h`);
    if (minutes > 0)
        result.push(`${minutes}min`);
    if (secondsType !== 'hide' && seconds > 0) {
        if (secondsType === 'truncate' && result.length === 0)
            result.push('< 1min');
        else
            result.push(`${seconds}s`);
    }
    return (result.length > 0) ? result.join(' ') : fallback;
};
/**
 * Retorna os bytes nos formatos KB, MB, GB, TB
 * @param a
 * @param b
 * @returns
 */
export const formatBytes = (a, b = 2) => {
    if (!+a)
        return '0 Bytes';
    const c = 0 > b ? 0 : b;
    const d = (Math.log(a) / Math.log(1024)) | 0;
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${['Bytes', 'KB', 'MB', 'GB', 'TB'][d]}`;
};
/**
 * Função que formata o scormScriptLink para apontar para o elemento HTML do scorm
 * @param link
 * @returns
 */
export const formatterScormScriptLink = (link) => {
    // Se for o antigo scorm
    if (link.split('filepath=').pop() === 'index_lms.html') {
        // @TODO: Revisar porque essa lógica não funciona mais
        // let result = link.replace('plantarScormPlayer.html?tenantId=', '')
        //   .replace('&path=scorms&scormId=', '/scorms/')
        // return result.replace('&filepath=index_lms', '/story_html5')
        return link;
    }
    else
        return link + '?autoplay=true#/'; // Se for o novo scorm
};
export const xlsExporter = (opt) => {
    const getFormatter = {
        'active-indicator': (value) => (value ? t('labels.active') : t('labels.inactive')),
        'type-people': formatter.typePeople,
        'format-cpfcnpj': formatter.cpfCnpj,
        'format-date': formatter.date,
        'format-status': (value) => (value ? t('labels.active') : t('labels.inactive')),
        'format-boolean': (value) => (value ? t('labels.yes') : t('labels.no')),
        'status-percentage': (value) => (value === 0 ? 'Inscrito' : `${value}% concluído`),
        'type-classificacao-cliente': formatter.typeClassificacaoCliente,
        'type-produto-aliare': formatter.typeProdutoAliare
    };
    const localData = JSON.parse(JSON.stringify(opt.data)) || [];
    const data = localData.map((el) => {
        const obj = {};
        (opt.columns || []).forEach((col) => {
            // Transforma a coluna, caso necessário
            if (col.transform) {
                if (typeof col.transform === 'function')
                    el[col.prop] = col.transform(el);
                // @ts-ignore
                else
                    el[col.prop] = getFormatter[col.transform](el[col.prop]);
            }
            // @ts-ignore
            obj[col.label] = el[col.prop];
        });
        return obj;
    });
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, opt.sheetName || 'Dados');
    writeFileXLSX(wb, `${opt.fileName}.xlsx`);
};
// Função que executa após o tempo informado
export function debounce(func, timeout = 500) {
    let timer = null;
    return (...args) => {
        if (timer)
            clearTimeout(timer);
        timer = setTimeout(() => {
            // @ts-ignore
            func.apply(this, args);
        }, timeout);
    };
}
/**
 * Função que transforma a primeira letra de uma palavra em maiúscula e o restante em minúscula
 * @param str
 */
export const captalizeWord = (str) => {
    const nStr = str
        .trim()
        .toLowerCase();
    return nStr[0]?.toUpperCase() + nStr.slice(1);
};
/**
 * Função que transforma a primeira letra de cada palavra em maiúscula e o restante em minúscula
 * ignorando as palavras passadas no segundo parâmetro
 * @param str
 * @param ignore
 * @returns
 * @example captalizeStr('maria da silva', ['da', 'de']) -> Maria da Silva
 */
export const captalizeStr = (str, ignore) => {
    return str?.split(' ')
        .filter((el) => el !== '')
        .map((el) => (!ignore?.includes(el) ? captalizeWord(el) : el))
        .join(' ');
};
/**
 * Função que usa a função captalizeStr seguindo convenções de nomes
 * @param name
 * @returns
 * @example MARIA DA SILVA -> Maria da Silva
 */
export const capitalizedName = (name) => {
    return captalizeStr(name, namePrepositions);
};
export const getInitials = (name) => {
    if (!name)
        return '';
    const names = `${name}`
        .replace(/\s+/g, ' ')
        .split(' ')
        ?.filter((el) => !namePrepositions.includes(el.toLowerCase()));
    return (names?.length > 1
        ? `${names[0][0]}${names[names.length - 1][0] || ''}`
        : `${names[0]?.[0] || ''}${names[0]?.[1] || ''}`).toUpperCase();
};
import iImage from '@/assets/placeholder/image.jpg';
import iVideo from '@/assets/placeholder/video.jpg';
import iLink from '@/assets/placeholder/link.jpg';
import iPdf from '@/assets/placeholder/pdf.jpg';
import iAudio from '@/assets/placeholder/audio.jpg';
import iDocument from '@/assets/placeholder/doc.jpg';
import iAssessment from '@/assets/placeholder/avaliacao.png';
import iScorm from '@/assets/placeholder/scorm.jpg';
import iDefault from '@/assets/placeholder/no_cover.jpg';
export const getMediaThumbnail = (obj) => {
    let tipo = typeof obj === 'object' ? obj.tipo?.toLowerCase() : obj?.toLowerCase();
    if ((tipo === 'link' && validateLink.verify(obj.link)) || validateLink.verify(obj.src))
        tipo = 'video';
    return (obj?.linkDaImagemDeCapa ||
        obj?.img ||
        {
            imagem: iImage,
            image: iImage,
            video: iVideo,
            link: iLink,
            pdf: iPdf,
            audio: iAudio,
            documento: iDocument,
            document: iDocument,
            avaliacao: iAssessment,
            assessment: iAssessment,
            scorm: iScorm
        }[tipo] || iDefault);
};
export const testsStatusTypes = computed(() => ({
    Aprovado: t('labels.approved'),
    Reprovado: t('labels.disapproved'),
    Pendente: t('labels.pending'),
    NovaTentativa: t('labels.newTry'),
    Desistencia: t('labels.withdrawal'),
    NaoRespondido: t('labels.notAnswered'),
    PendenteDeCorrecao: t('labels.pendingCorrection'),
}));
export const testsTypes = computed(() => ({
    Diagnostica: t('labels.diagnostic'),
    Reacao: t('labels.reaction'),
    Aprendizagem: t('labels.learning'),
    Comportamento: t('labels.behavior')
}));
export const questionsTypes = computed(() => ({
    Objetiva: t('labels.objective'),
    Multipla: t('labels.multipleChoice'),
    VerdadeiroFalso: t('labels.trueOrFalse'),
    Escala: t('labels.scale'),
    Dissertativa: t('labels.essay'),
    Pratica: t('labels.practice')
}));
export const filterMediaTypes = computed(() => ({
    default: [t('labels.all')],
    video: [t('labels.video')],
    link: [t('labels.link')],
    pdf: [t('labels.pdf')],
    audio: [t('labels.audio')],
    imagem: [t('labels.image')],
    documento: [t('input.document')],
    scorm: [t('labels.scorm')]
}));
export const notificationsEventOptions = [
    {
        value: 'NaoAcessouPlataforma',
        label: t('mainContent.automatedNotifications.events.notAccessEnvironment')
    },
    {
        value: 'NaoIniciouCurso',
        label: t('mainContent.automatedNotifications.events.notStartedCourse')
    },
    {
        value: 'NaoIniciouFormacao',
        label: t('mainContent.automatedNotifications.events.notStartedTrail')
    },
    {
        value: 'VencimentoProximoDoCurso',
        label: t('mainContent.automatedNotifications.events.expireCourse')
    },
    {
        value: 'VencimentoProximoDaFormacao',
        label: t('mainContent.automatedNotifications.events.expireTrail')
    }
];
export const sendingIntervalOptions = [
    { value: 'UnicaVez', label: t('mainContent.automatedNotifications.sendingInterval.once') },
    { value: 'Diariamente', label: t('mainContent.automatedNotifications.sendingInterval.day') },
    { value: 'Semanalmente', label: t('mainContent.automatedNotifications.sendingInterval.week') },
    { value: 'Mensalmente', label: t('mainContent.automatedNotifications.sendingInterval.month') }
];
export const mediaType = computed(() => ({
    audio: t('labels.audio'),
    link: t('labels.link'),
    imagem: t('labels.image'),
    video: t('labels.video'),
    pdf: t('labels.pdf'),
    documento: t('input.document'),
    scorm: t('labels.scorm'),
    curso: t('labels.course'),
    trilha: t('labels.trail'),
    formacao: t('labels.trail')
}));
export const languageType = computed(() => ({
    en: t('labels.en'),
    pt: t('labels.pt'),
    es: t('labels.es'),
}));
export const downloadFile = (url, name) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name || url.split('/').pop() || '');
    document.body.appendChild(link);
    link.click();
    nextTick(() => {
        document.body.removeChild(link);
    });
};
/**
 * Função que retorna uma tradução, podendo ser utilizada nas stores evitando erro por importar o {t}
 * Colocado o nome i18N para não conflitar com o import i18n
 * @param str => 'modals.associations'
 * @param params => { title: 'placeholder.courses'}
 * @param plural => 2
 */
export const i18N = (key, params, plural) => {
    return t(key, params, plural);
};
