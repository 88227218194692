import { defineComponent as _defineComponent } from 'vue';
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = { class: "relative" };
const _hoisted_2 = { class: "max-w-full" };
const _hoisted_3 = ["width", "height"];
const _hoisted_4 = { key: 0 };
const _hoisted_5 = {
    key: 0,
    class: "absolute dark:bg-black/60 top-0 left-0 w-full h-full flex items-center justify-center"
};
const _hoisted_6 = { class: "ml-4 text-xs" };
const _hoisted_7 = { class: "flex flex-col gap-2 p-4" };
const _hoisted_8 = { class: "block text-sm text-slate-500" };
const _hoisted_9 = { class: "flex items-center" };
const _hoisted_10 = { class: "ml-2 text-sm" };
const _hoisted_11 = { class: "flex gap-4" };
const _hoisted_12 = { class: "block text-sm text-slate-500" };
const _hoisted_13 = { key: 0 };
const _hoisted_14 = { class: "block text-sm text-slate-500" };
const _hoisted_15 = { class: "flex gap-4" };
const _hoisted_16 = {
    key: 0,
    class: "pe-button-group"
};
const _hoisted_17 = { class: "block text-sm text-slate-500" };
const _hoisted_18 = { class: "pe-button-group" };
const _hoisted_19 = { class: "block text-sm text-slate-500" };
const _hoisted_20 = { class: "block text-sm text-slate-500" };
const _hoisted_21 = { key: 0 };
const _hoisted_22 = { class: "block text-sm text-slate-500" };
const _hoisted_23 = { class: "flex gap-1" };
const _hoisted_24 = { class: "m-0 p-2 flex flex-col gap-0.5 overflow-y-auto max-h-[200px] text-sm text-neutral-800" };
const _hoisted_25 = ["onClick"];
const _hoisted_26 = { class: "m-0 p-2 flex flex-col gap-0.5 overflow-y-auto max-h-[200px] text-sm text-neutral-800" };
const _hoisted_27 = ["onClick"];
const _hoisted_28 = {
    key: 1,
    class: "w-full"
};
const _hoisted_29 = { class: "block text-sm text-slate-500" };
import { onMounted, ref, nextTick, watch, reactive, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMouseInElement, useMousePressed, useRafFn, useDraggable } from '@vueuse/core';
import { clamp, swapIndex } from '@/common/util';
import UiButton from '@/components/ui/button.vue';
import UiDropdown from '@/components/ui/dropdown.vue';
import UiIcon from '@/components/ui/icon.vue';
import UiInput from '@/components/ui/input.vue';
import UiSlider from '@/components/ui/slider.vue';
import BackPage from './backPage.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'canvas',
    props: {
        editable: { type: Boolean },
        modelValue: {},
        viewing: {},
        debug: { type: Boolean },
        preview: { type: Boolean },
        tags: {},
        courseItems: {},
        trailItems: {}
    },
    emits: ['update:modelValue', 'removeLayer', 'renderComplete'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const { t } = useI18n();
        const init = ref(true);
        const colorInput = ref();
        const props = __props;
        const emit = __emit;
        const structure = computed({
            get: () => props.modelValue,
            set: () => {
                emit('update:modelValue', props.modelValue);
            }
        });
        const canvas = ref();
        let ctx = null;
        const page = computed(() => {
            let { width, height, dpi } = structure.value.settings?.page || {};
            const dpiRatio = (dpi || 96) / 25.4;
            let nWidth = (width >= height ? width : height) || 297;
            let nHeight = (width >= height ? height : width) || 210;
            return {
                ...structure.value.settings?.page,
                w: (nWidth * dpiRatio) | 0,
                h: (nHeight * dpiRatio) | 0
            };
        });
        // isDraging inside canvas
        const isDragging = ref(false);
        // Movimentar a janela de
        const draggableToolbox = ref();
        const { style: dragStyle } = useDraggable(draggableToolbox, {
            exact: true,
            initialValue: { x: 40, y: 40 }
        });
        // Coposables
        const { isOutside, elementX, elementY } = useMouseInElement(canvas);
        const { pressed } = useMousePressed();
        const lastClick = reactive({
            x: 0,
            y: 0
        });
        const targetElementIndex = ref(-1);
        const targetElement = computed(() => {
            if (targetElementIndex.value === -1)
                return null;
            return structure.value?.layers?.[targetElementIndex.value];
        });
        // const targetElement = ref<Layer | null>(null) // Elemento selecionado por ultimo
        const opacityPercent = computed({
            get: () => ((Number(targetElement.value.opacity) / 255) * 100).toFixed(2),
            set: (val) => (targetElement.value.opacity = (clamp(Number(val), 0, 100) / 100) * 255)
        });
        const utils = {
            createRect: (rect) => ({ ...rect }),
            pageSize: () => ({
                w: structure.value.page?.width || canvas.value.width,
                h: structure.value.page?.height || canvas.value.height
            }),
            getRelativeX: (x) => (x * utils.pageSize().w) / 100,
            getRelativeY: (y) => (y * utils.pageSize().h) / 100,
            getRelativeRect: (rect) => ({
                x: utils.getRelativeX(rect.x),
                y: utils.getRelativeY(rect.y),
                w: utils.getRelativeX(rect.w),
                h: utils.getRelativeY(rect.h)
            }),
            drawDebugRect: (rect, force) => {
                if (!props.debug || force)
                    return;
                const prevColor = ctx.strokeStyle;
                const prevAlpha = ctx.globalAlpha;
                ctx.globalAlpha = 1;
                ctx.strokeStyle = !force ? '#FF0000' : '#0000FF';
                ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
                ctx.strokeStyle = prevColor;
                ctx.globalAlpha = prevAlpha;
            },
            addText: () => {
                if (!structure.value.layers)
                    structure.value.layers = [];
                structure.value.layers?.push({
                    rect: utils.createRect({ x: 20, y: 20, w: 20, h: 5 }),
                    text: {
                        content: 'Texto',
                        size: 14
                    },
                    opacity: 255,
                    flip: false
                });
            }
        };
        const mouse = computed(() => ({
            x: utils.getRelativeX(clamp((elementX.value / canvas.value.width) * 100, 0, 100)),
            y: utils.getRelativeY(clamp((elementY.value / canvas.value.height) * 100, 0, 100))
        }));
        const calcTextLenght = (text, width) => {
            let tmp = text;
            if (props.preview) {
                tmp = tmp
                    .replace('#NOME#', props.tags?.name || '#NOME#')
                    .replace('#CARGO#', props.tags?.cargo || '#CARGO#')
                    .replace('#TITULO#', props.tags?.title || '#TITULO#')
                    .replace('#DT_CONCLUSAO#', props.tags?.date || '#DT_CONCLUSAO#')
                    .replace('#CARGA_HORARIA#', props.tags?.time || '#CARGA_HORARIA#')
                    .replace('#CPF#', props.tags?.cpf || '#CPF#')
                    .replace('#DT_INSCRICAO#', props.tags?.init || '#DT_INSCRICAO#');
            }
            return getLines(tmp, width);
        };
        const updateTextContentHeight = () => {
            if (typeof targetElement.value.text !== 'undefined') {
                const fontSize = targetElement.value?.text?.size || 14;
                ctx.font = `${fontSize}px Arial`;
                const len = calcTextLenght(targetElement.value?.text?.content, utils.getRelativeX(targetElement.value?.rect?.w)).length;
                targetElement.value.rect.h = (len * fontSize * 100) / utils.pageSize().h;
            }
        };
        const reOrder = (target) => {
            if (targetElementIndex.value === -1)
                return;
            swapIndex(structure.value.layers, targetElementIndex.value, targetElementIndex.value + target);
            targetElementIndex.value = targetElementIndex.value + target;
        };
        // Remove o elemento selecionado
        const removeElement = () => {
            if (targetElementIndex.value < 0)
                return;
            emit('removeLayer', targetElementIndex.value);
        };
        watch(() => targetElement.value?.text?.content, () => {
            if (!targetElement.value?.text?.content)
                return;
            updateTextContentHeight();
        });
        const update = () => {
            if (!isDragging.value || isOutside.value)
                return;
            if (targetElement.value?.rect && pressed.value) {
                targetElement.value.rect.x = targetElement.value.rect.x + ((mouse.value.x - lastClick.x) / utils.pageSize().w) * 100;
                targetElement.value.rect.y = targetElement.value.rect.y + ((mouse.value.y - lastClick.y) / utils.pageSize().h) * 100;
                lastClick.x = mouse.value.x;
                lastClick.y = mouse.value.y;
            }
        };
        const mouseUp = () => {
            isDragging.value = false;
        };
        const mouseDown = () => {
            if (!props.editable)
                return;
            isDragging.value = true;
            // Procura um elemento
            const len = (structure.value?.layers || []).length;
            for (let idx = len - 1; idx >= 0; idx--) {
                const layer = structure.value.layers[idx];
                const { x, y, w, h } = utils.getRelativeRect(layer.rect);
                if (layer?.text) {
                    if (mouse.value.x >= x && mouse.value.x <= x + w && mouse.value.y >= y && mouse.value.y <= y + h) {
                        targetElementIndex.value = idx;
                        break;
                    }
                }
                else if (layer?.img) {
                    if (!layer.img?.element)
                        continue;
                    const { width, height } = layer.img?.element || { width: 0, height: 0 };
                    if (mouse.value.x >= x &&
                        mouse.value.x <= x + (width * layer.rect.w) / 100 &&
                        mouse.value.y >= y &&
                        mouse.value.y <= y + height) {
                        targetElementIndex.value = idx;
                        break;
                    }
                }
                targetElementIndex.value = -1;
            }
            lastClick.x = mouse.value.x;
            lastClick.y = mouse.value.y;
        };
        const getLines = (text, maxWidth) => {
            let lines = [];
            text.split('\n').map((line) => {
                let words = line.split(' ');
                let currentLine = words[0];
                for (let i = 1; i < words.length; i++) {
                    let word = words[i];
                    let width = ctx.measureText(currentLine + ' ' + word).width;
                    if (width < maxWidth) {
                        currentLine += ' ' + word;
                    }
                    else {
                        lines.push(currentLine);
                        currentLine = word;
                    }
                }
                lines.push(currentLine);
            });
            return lines;
        };
        const draw = () => {
            const { width, height } = structure.value?.page || canvas.value;
            ctx.canvas.width = width;
            ctx.canvas.height = height;
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            if (structure.value.background?.img.element?.src) {
                const mirrorX = structure.value.background?.mirrorX ? -1 : 1;
                const mirrorY = structure.value.background?.mirrorY ? -1 : 1;
                ctx.scale(mirrorX * 1, mirrorY * 1);
                ctx.drawImage(structure.value.background?.img.element, 0, 0, mirrorX * width, mirrorY * height);
                ctx.scale(mirrorX * 1, mirrorY * 1);
            }
            for (let i = 0; i < structure.value?.layers?.length; i++) {
                const layer = structure.value?.layers[i];
                ctx.globalAlpha = typeof layer?.opacity !== 'undefined' ? layer.opacity / 255 : 1;
                // Tipo Texto
                if (layer.text) {
                    const { x, y, w, h } = utils.getRelativeRect(layer.rect);
                    let alignModifier = 0;
                    if (['left', 'center', 'right', 'start', 'end'].includes(layer.text.align)) {
                        ctx.textAlign = layer.text.align;
                        if (layer.text.align === 'center')
                            alignModifier = w / 2;
                        else if (['right', 'end'].includes(layer.text.align))
                            alignModifier = w;
                    }
                    const fontStyles = [];
                    // Color
                    ctx.fillStyle = layer.text.color || '#000000';
                    // Bold
                    if (layer.text.bold)
                        fontStyles.push('bold');
                    // Italic
                    if (layer.text.italic)
                        fontStyles.push('italic');
                    // Font Size
                    const fontSize = layer.text?.size || 14;
                    fontStyles.push(`${fontSize}px`);
                    // Font Family
                    fontStyles.push(layer.text.fontFamily || 'Arial');
                    // @TODO: Underline
                    // O sublinhado precisa ser calculado manualmente
                    ctx.font = fontStyles.join(' ');
                    calcTextLenght(layer.text.content, w).map((line, index) => {
                        ctx.fillText(line, x + alignModifier, y + (index + 1) * fontSize);
                    });
                    // Linha de debug
                    utils.drawDebugRect({ x, y, w, h });
                }
                // Tipo Imagem
                if (layer.img?.element?.src) {
                    const { x, y } = utils.getRelativeRect(layer.rect);
                    const { w, h } = layer.rect;
                    const img = layer.img.element;
                    if (img) {
                        const imgW = (img.width * w) / 100;
                        const imgH = (img.height * h) / 100;
                        ctx.drawImage(img, x, y, imgW, imgH);
                        // Linha de debug
                        utils.drawDebugRect({ x, y, w: imgW, h: imgH });
                    }
                }
            }
            if (targetElement.value?.rect) {
                // Linha de debug
                ctx.globalAlpha = 0.5;
                const { x, y, w, h } = utils.getRelativeRect(targetElement.value.rect);
                if (targetElement.value?.text)
                    ctx.strokeRect(x, y, w, h);
                ctx.globalAlpha = 1;
            }
            // Linha do mouse (DEBUG)
            // const prevColor = ctx.strokeStyle
            // ctx.strokeStyle = '#00FF00'
            // ctx.strokeRect(lastClick.x, lastClick.y, 8, 8)
            // ctx.strokeStyle = prevColor
            // const mouseX = clamp((elementX.value / canvas.value.width) * 100, 0, 100)
            // const mouseY = clamp((elementY.value / canvas.value.height) * 100, 0, 100)
            // ctx.strokeStyle = '#0000FF'
            // ctx.strokeRect(utils.getRelativeX(mouseX), utils.getRelativeY(mouseY), 8, 8)
            // ctx.strokeStyle = prevColor
        };
        const { resume } = useRafFn(() => {
            if (props.editable)
                update();
            draw();
        }, { immediate: false });
        const activateColorInput = () => {
            if (!colorInput.value)
                return;
            colorInput.value.click();
        };
        watchEffect(() => {
            if (!canvas.value)
                return;
            canvas.value.width = page.value.w;
            canvas.value.height = page.value.h;
        });
        const readyForPrint = () => {
            emit('renderComplete', true);
        };
        onMounted(async () => {
            ctx = canvas.value?.getContext('2d') || null;
            const imgsLoaded = [];
            nextTick(async () => {
                // Carrega as imagens caso necessário
                if (structure.value?.background?.img.url && !structure.value.background?.img.element?.src) {
                    let imgPromise = new Promise((resolve) => {
                        const bgImg = new Image();
                        bgImg.src = structure.value?.background.img.url.replace('https://', '//').replace('http://', '//');
                        bgImg.setAttribute('crossorigin', 'anonymous');
                        bgImg.onload = () => {
                            structure.value.background.img.element = bgImg;
                            resolve(true);
                        };
                    });
                    imgsLoaded.push(imgPromise);
                }
                structure.value.layers
                    ?.filter((layer) => layer.img?.url && !layer.img.element?.src)
                    .map((layer) => {
                    let imgPromise = new Promise((resolve) => {
                        const img = new Image();
                        img.src = layer.img.url.replace('https://', '//').replace('http://', '//');
                        img.setAttribute('crossorigin', 'anonymous');
                        img.onload = () => {
                            layer.img.element = img;
                            resolve(true);
                        };
                    });
                    imgsLoaded.push(imgPromise);
                });
                await Promise.all(imgsLoaded);
                readyForPrint();
                init.value = false;
                resume();
            });
        });
        __expose({
            refresh: () => {
                resume();
            }
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _withDirectives(_createElementVNode("canvas", {
                            id: "certCanvas",
                            ref_key: "canvas",
                            ref: canvas,
                            width: page.value.w,
                            height: page.value.h,
                            class: "max-w-full",
                            onMousedown: mouseDown,
                            onMouseup: mouseUp
                        }, null, 40, _hoisted_3), [
                            [_vShow, !structure.value.settings?.page?.backSide || ['front', 'both'].includes(_ctx.viewing)]
                        ]),
                        (structure.value.settings?.page?.backSide && ['back', 'both'].includes(_ctx.viewing))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createVNode(BackPage, {
                                    width: page.value.w,
                                    height: page.value.h,
                                    courseItems: _ctx.courseItems || [],
                                    trailItems: _ctx.trailItems || []
                                }, null, 8, ["width", "height", "courseItems", "trailItems"])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    (init.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Aguarde enquanto carregamos os elementos do certificado... "))
                        : _createCommentVNode("", true)
                ]),
                (_ctx.editable && targetElement.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "fixed w-[340px] max-w-full overflow-hidden rounded-lg bg-white shadow-lg",
                        style: _normalizeStyle(_unref(dragStyle))
                    }, [
                        _createElementVNode("div", {
                            ref_key: "draggableToolbox",
                            ref: draggableToolbox,
                            class: "flex h-5 items-center justify-between bg-slate-400"
                        }, [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('labels.option', 2)), 1),
                            _createVNode(UiButton, {
                                type: "ghost",
                                class: "relative cursor-pointer border-0 bg-transparent outline-0 hover:text-neutral-500",
                                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (targetElementIndex.value = -1), ["prevent"]))
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(UiIcon, {
                                        size: "12",
                                        icon: "x"
                                    })
                                ]),
                                _: 1
                            })
                        ], 512),
                        _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", null, [
                                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('labels.opacity')), 1),
                                _createElementVNode("div", _hoisted_9, [
                                    _createVNode(UiSlider, {
                                        modelValue: targetElement.value.opacity,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((targetElement.value.opacity) = $event)),
                                        min: 0,
                                        max: 255
                                    }, null, 8, ["modelValue"]),
                                    _createElementVNode("span", _hoisted_10, _toDisplayString(opacityPercent.value) + "%", 1)
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", null, [
                                    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('labels.width')), 1),
                                    _createVNode(UiInput, {
                                        modelValue: targetElement.value.rect.w,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((targetElement.value.rect.w) = $event)),
                                        type: "number",
                                        onChange: _cache[3] || (_cache[3] = () => updateTextContentHeight()),
                                        class: "w-20 h-6"
                                    }, null, 8, ["modelValue"])
                                ]),
                                (targetElement.value.img)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                        _createElementVNode("label", _hoisted_14, _toDisplayString(_unref(t)('labels.height')), 1),
                                        _createVNode(UiInput, {
                                            modelValue: targetElement.value.rect.h,
                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((targetElement.value.rect.h) = $event)),
                                            type: "number",
                                            class: "w-20 h-6"
                                        }, null, 8, ["modelValue"])
                                    ]))
                                    : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                                (typeof targetElement.value.text?.content !== 'undefined')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                        _createElementVNode("label", _hoisted_17, _toDisplayString(_unref(t)('labels.align')), 1),
                                        _createVNode(UiButton, {
                                            type: "ghost",
                                            size: "small",
                                            class: "p-1",
                                            onClick: _cache[5] || (_cache[5] = () => (targetElement.value.text.align = 'left'))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiIcon, {
                                                    size: "18",
                                                    icon: "align-left"
                                                })
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(UiButton, {
                                            type: "ghost",
                                            size: "small",
                                            class: "p-1",
                                            onClick: _cache[6] || (_cache[6] = () => (targetElement.value.text.align = 'center'))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiIcon, {
                                                    size: "18",
                                                    icon: "align-center"
                                                })
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(UiButton, {
                                            type: "ghost",
                                            size: "small",
                                            class: "p-1",
                                            onClick: _cache[7] || (_cache[7] = () => (targetElement.value.text.align = 'right'))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiIcon, {
                                                    size: "18",
                                                    icon: "align-right"
                                                })
                                            ]),
                                            _: 1
                                        })
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_18, [
                                    _createElementVNode("label", _hoisted_19, _toDisplayString(_unref(t)('labels.layer')), 1),
                                    _createVNode(UiButton, {
                                        type: "ghost",
                                        size: "small",
                                        class: "p-1",
                                        disabled: targetElementIndex.value < 1,
                                        onClick: _cache[8] || (_cache[8] = ($event) => (reOrder(-1)))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiIcon, {
                                                size: "18",
                                                icon: "arrow-bar-to-down"
                                            })
                                        ]),
                                        _: 1
                                    }, 8, ["disabled"]),
                                    _createVNode(UiButton, {
                                        type: "ghost",
                                        class: "p-1 ml-1",
                                        size: "small",
                                        disabled: targetElementIndex.value === -1 || targetElementIndex.value === structure.value?.layers.length - 1,
                                        onClick: _cache[9] || (_cache[9] = ($event) => (reOrder(1)))
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiIcon, {
                                                size: "18",
                                                icon: "arrow-bar-to-up"
                                            })
                                        ]),
                                        _: 1
                                    }, 8, ["disabled"])
                                ]),
                                _createElementVNode("div", null, [
                                    _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('labels.actions')), 1),
                                    _createVNode(UiButton, {
                                        type: "danger",
                                        class: "px-2 py-1 text-xs",
                                        onClick: _cache[10] || (_cache[10] = () => removeElement())
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(UiIcon, {
                                                icon: "trash",
                                                size: "14"
                                            }),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('button.delete')), 1)
                                        ]),
                                        _: 1
                                    })
                                ])
                            ]),
                            (typeof targetElement.value.text?.content !== 'undefined')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                    _createElementVNode("label", _hoisted_22, _toDisplayString(_unref(t)('labels.formatting')), 1),
                                    _createElementVNode("div", _hoisted_23, [
                                        _createVNode(UiButton, {
                                            type: "ghost",
                                            class: "p-1",
                                            title: _ctx.$t('labels.textColor'),
                                            onClick: _cache[12] || (_cache[12] = () => activateColorInput())
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiIcon, { icon: "text-color" }),
                                                _withDirectives(_createElementVNode("input", {
                                                    ref_key: "colorInput",
                                                    ref: colorInput,
                                                    type: "color",
                                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((targetElement.value.text.color) = $event)),
                                                    class: "hidden"
                                                }, null, 512), [
                                                    [_vModelText, targetElement.value.text.color]
                                                ])
                                            ]),
                                            _: 1
                                        }, 8, ["title"]),
                                        _createVNode(UiDropdown, null, {
                                            content: _withCtx(() => [
                                                _createElementVNode("ul", _hoisted_24, [
                                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Lucida Sans Unicode', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana'], (font) => {
                                                        return _createElementVNode("li", {
                                                            key: font,
                                                            class: _normalizeClass(["hover:bg-neutral-200 transition-colors cursor-pointer px-2 py-1 rounded", { 'bg-neutral-300 font-semibold': targetElement.value.text.fontFamily === font }]),
                                                            onClick: () => (targetElement.value.text.fontFamily = font)
                                                        }, [
                                                            _createTextVNode(_toDisplayString(font) + " ", 1),
                                                            (targetElement.value.text.fontFamily === font)
                                                                ? (_openBlock(), _createBlock(UiIcon, {
                                                                    key: 0,
                                                                    icon: "check",
                                                                    size: "12px",
                                                                    class: "text-emerald-500"
                                                                }))
                                                                : _createCommentVNode("", true)
                                                        ], 10, _hoisted_25);
                                                    }), 64))
                                                ])
                                            ]),
                                            default: _withCtx(() => [
                                                _createVNode(UiButton, {
                                                    type: "ghost",
                                                    class: "p-1 mr-2",
                                                    title: _ctx.$t('labels.font')
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(UiIcon, { icon: "text-size" })
                                                    ]),
                                                    _: 1
                                                }, 8, ["title"])
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(UiDropdown, null, {
                                            content: _withCtx(() => [
                                                _createElementVNode("ul", _hoisted_26, [
                                                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(112, (index) => {
                                                        return _createElementVNode("li", {
                                                            key: index,
                                                            onClick: () => targetElement.value.text.size = 8 + index,
                                                            class: _normalizeClass(["hover:bg-neutral-200 transition-colors cursor-pointer px-2 py-1 rounded", { 'bg-neutral-300 font-semibold': targetElement.value.text.size === (8 + index) }])
                                                        }, [
                                                            _createTextVNode(_toDisplayString(8 + index) + "px ", 1),
                                                            (targetElement.value.text.size === (8 + index))
                                                                ? (_openBlock(), _createBlock(UiIcon, {
                                                                    key: 0,
                                                                    icon: "check",
                                                                    size: "12px",
                                                                    class: "text-emerald-500"
                                                                }))
                                                                : _createCommentVNode("", true)
                                                        ], 10, _hoisted_27);
                                                    }), 64))
                                                ])
                                            ]),
                                            default: _withCtx(() => [
                                                _createVNode(UiButton, {
                                                    type: "ghost",
                                                    class: "p-1 mr-2",
                                                    title: _ctx.$t('labels.textSize')
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(UiIcon, { icon: "text-size" })
                                                    ]),
                                                    _: 1
                                                }, 8, ["title"])
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(UiButton, {
                                            type: "ghost",
                                            class: "p-1",
                                            title: _ctx.$t('labels.bold'),
                                            onClick: _cache[13] || (_cache[13] = () => (targetElement.value.text.bold = !targetElement.value.text.bold))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiIcon, { icon: "bold" })
                                            ]),
                                            _: 1
                                        }, 8, ["title"]),
                                        _createVNode(UiButton, {
                                            type: "ghost",
                                            class: "p-1",
                                            title: _ctx.$t('labels.italic'),
                                            onClick: _cache[14] || (_cache[14] = () => (targetElement.value.text.italic = !targetElement.value.text.italic))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(UiIcon, { icon: "italic" })
                                            ]),
                                            _: 1
                                        }, 8, ["title"])
                                    ])
                                ]))
                                : _createCommentVNode("", true),
                            (typeof targetElement.value.text?.content !== 'undefined')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                    _createElementVNode("label", _hoisted_29, _toDisplayString(_unref(t)('labels.content')), 1),
                                    _createVNode(UiInput, {
                                        type: "textarea",
                                        modelValue: targetElement.value.text.content,
                                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event) => ((targetElement.value.text.content) = $event)),
                                        cols: "30",
                                        rows: "8",
                                        class: "w-full"
                                    }, null, 8, ["modelValue"])
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ], 4))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
