import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
class OidcService {
    userManager;
    settings;
    constructor() {
        const oidc = window?.configuration?.oidc || {};
        Object.keys(oidc).forEach((key) => {
            switch (`${oidc[key]}`.trim()) {
                case '__Oidc.AuthorityUrl__':
                case '__Oidc.RedirectUri__':
                case '__Oidc.ClientId__':
                case '__Oidc.SilentRedirectUri__':
                case '__Oidc.SsoMode__':
                case '__Oidc.PostLogoutRedirectUri__':
                case '__Oidc.Scope__':
                    oidc[key] = '';
                    break;
            }
        });
        this.settings = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: oidc?.authority || '',
            client_id: oidc?.client_id || '',
            redirect_uri: `${window.location.origin}/auth/sso`,
            silent_redirect_uri: `${window.location.origin}/auth/sso-silent`,
            post_logout_redirect_uri: `${window.location.origin}/logout`,
            scope: oidc?.scope || '',
            loadUserInfo: true
        };
        // Log.setLogger(console)
        // Log.setLevel(Log.INFO)
        this.userManager = new UserManager(this.settings);
    }
    getUser(cb) {
        return this.userManager.getUser().then((user) => {
            if (cb && typeof cb === 'function')
                cb(user);
            return user;
        });
    }
    signinRedirect() {
        return this.userManager.signinRedirect();
    }
    signinRedirectCallback = () => {
        return this.userManager.signinRedirectCallback();
    };
    signinSilent = () => {
        return this.userManager.signinSilent();
    };
    signinSilentCallback = () => {
        return this.userManager.signinSilentCallback();
    };
    signinCallback = () => {
        return this.userManager.signinCallback();
    };
    signoutRedirect = () => {
        return this.userManager.signoutRedirect();
    };
    signoutSilent = () => {
        return this.userManager.signoutSilent();
    };
    getAccessToken = () => {
        return this.userManager.getUser()?.then((data) => {
            return data ? data.access_token : null;
        });
    };
    hasAuthority = () => {
        return `${this.settings.authority}`.length > 0;
    };
}
export default new OidcService();
