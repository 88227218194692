import { createRouter, createWebHistory } from 'vue-router';
import webStorage from '@/common/webStorage';
// Layout
import MainLayout from '@/layout/modern/index.vue';
import Blank from '@/layout/blank.vue';
// Paginas de navegação
// @TODO: as paginas de navegação (dos módulos) podem virar um arquivo só
// @TODO: Verficar necessidade desses arquivos
// import NavContas from '@/views/NavContas.vue'
// import NavConteudo from '@/views/NavConteudo.vue'
// import NavConfig from '@/views/NavConfiguracao.vue'
// import NavGestao from '@/views/NavGestao.vue'
// import NavGamificacao from '@/views/NavGamificacao.vue'
// import NavComunicacao from '@/views/NavComunicacao.vue'
import useAuthStore from '@/stores/authStore';
import useGrupoPermissoesStore from '@/stores/grupoDePermissoesStore';
import LoginLayout from '@/views/auth/index.vue';
import { useSessionStorage } from '@vueuse/core';
/**
 * Rotas V2 (Para padronizar em inglês)
 */
// eslint-disable-next-line no-unused-vars
const assessments = {
    manager: [
        {
            path: 'assessment',
            name: 'management.assessment.list',
            component: () => import('@/views/gestao/avaliacao/index.vue'),
        },
        {
            path: 'avaliacao/:id',
            name: 'management.assessment.view',
            component: () => import('@/views/gestao/avaliacao/view.vue'),
        },
        {
            path: 'avaliacao/:id/answer',
            name: 'management.assessment.answer',
            component: () => import('@/views/gestao/avaliacao/components/answerAssessment.vue'),
        }
    ]
};
const routes = [
    {
        path: '/auth',
        name: 'auth',
        component: LoginLayout,
        redirect: '/login',
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/auth/login.vue')
            },
            {
                path: '/auth/recover',
                name: 'auth.recover',
                component: () => import('@/views/auth/recover.vue')
            },
            {
                path: '/auth/change-password',
                name: 'auth.change.password',
                component: () => import('@/views/auth/password.vue')
            },
            {
                path: '/auth/first-access',
                name: 'auth.first.access',
                component: () => import('@/views/auth/password.vue')
            },
            {
                path: '/auth/sso',
                name: 'auth.sso',
                component: () => import('@/views/auth/sso.vue')
            },
            {
                path: '/auth/aad',
                name: 'auth.aad',
                component: () => import('@/views/auth/aad.vue')
            },
            {
                path: '/auth/external-login',
                name: 'auth.external.login',
                component: () => import('@/views/auth/external-login.vue')
            }
        ]
    },
    {
        path: '/partner',
        name: 'partner',
        component: () => import('@/views/userPartner/index.vue')
    },
    {
        // Layout principal
        path: '/',
        name: 'main',
        redirect: '/aprendizado/home',
        component: MainLayout,
        meta: {
            requiresAuth: true,
            breadcrumbs: ['learning']
        },
        children: [
            {
                path: 'aprendizado',
                name: 'aprendizado',
                component: Blank,
                redirect: '/aprendizado/home',
                meta: { title: 'menu.learning', permission: 'Aprendizado' },
                children: [
                    {
                        path: 'home',
                        name: 'aprendizado.home',
                        component: () => import('@/views/aprendizado/home/index.vue'),
                        meta: { title: 'menu.learning', permission: 'Aprendizado', filterPermission: true }
                    },
                    {
                        path: 'secao/:id',
                        name: 'aprendizado.secao',
                        component: () => import('@/views/aprendizado/home/homeSecao.vue')
                    }
                ]
            },
            {
                path: 'about/:str',
                name: 'about',
                component: () => import('@/views/aprendizado/salaDeAula/detalhe.vue')
            },
            {
                path: 'learn/:str',
                name: 'learn',
                component: () => import('@/views/aprendizado/salaDeAula/salaDeAula.vue'),
                meta: {
                    classRoom: true
                },
                children: [
                    {
                        path: ':step',
                        name: 'learn.content',
                        component: () => import('@/views/aprendizado/salaDeAula/components/content.vue')
                    }
                ]
            },
            {
                path: 'learning-center',
                name: 'learningCenter',
                component: Blank,
                meta: {
                    focusMode: true,
                    classRoom: true,
                    newLayout: true,
                    sidebarMaxWidth: 300
                },
                children: [
                    {
                        path: 'view/:str',
                        name: 'learningCenter.classRoom',
                        component: () => import('@/views/learningCenter/classRoom/index.vue'),
                        children: [
                            {
                                path: ':step',
                                name: 'learningCenter.classRoom.content',
                                component: () => import('@/views/aprendizado/salaDeAula/components/content.vue')
                            }
                        ]
                    }
                ]
            },
            {
                // Sidebar Contas
                path: 'conta',
                name: 'account',
                component: Blank,
                meta: {
                    title: 'menu.accounts',
                    permission: 'Contas',
                    filterPermission: true,
                    breadcrumbs: ['account']
                },
                children: [
                    {
                        path: 'pessoa',
                        name: 'companies',
                        component: () => import('@/views/companies/index.vue'),
                        meta: {
                            title: 'menu.companies',
                            permission: 'Empresas',
                            filterPermission: true,
                            breadcrumbs: ['account', 'companies']
                        }
                    },
                    {
                        path: 'contrato',
                        name: 'contracts',
                        component: () => import('@/views/contrato/index.vue'),
                        meta: {
                            title: 'menu.contracts',
                            permission: 'Contratos',
                            filterPermission: true,
                            breadcrumbs: ['account', 'contracts']
                        }
                    },
                    {
                        path: 'unidade',
                        name: 'units',
                        component: () => import('@/views/unidadesNegocio/index.vue'),
                        meta: {
                            title: 'menu.units',
                            permission: 'Unidades de Negócio',
                            filterPermission: true,
                            breadcrumbs: ['account', 'units']
                        }
                    },
                    {
                        path: 'departamento',
                        name: 'departments',
                        component: () => import('@/views/contas/views/departamentos/departamentoIndex.vue'),
                        meta: {
                            title: 'menu.departments',
                            permission: 'Departamentos',
                            filterPermission: true,
                            breadcrumbs: ['account', 'departments']
                        }
                    },
                    {
                        path: 'cargo',
                        name: 'roles',
                        component: () => import('@/views/contas/views/cargos/cargoIndex.vue'),
                        meta: {
                            title: 'menu.jobsTitles',
                            permission: 'Cargos',
                            filterPermission: true,
                            breadcrumbs: ['account', 'roles']
                        }
                    },
                    {
                        path: 'usuario',
                        name: 'users',
                        component: () => import('@/views/contas/views/usuarios/index.vue'),
                        meta: {
                            title: 'menu.users',
                            permission: 'Usuários',
                            filterPermission: true,
                            breadcrumbs: ['account', 'users']
                        }
                    },
                    {
                        path: 'meu-contrato',
                        name: 'my-contract',
                        component: () => import('@/views/meuContrato/index.vue'),
                        meta: {
                            title: 'menu.myContract',
                            permission: 'Meu Contrato',
                            filterPermission: true,
                            breadcrumbs: ['account', 'my-contract']
                        }
                    }
                ]
            },
            {
                path: 'content',
                name: 'content-v2',
                component: Blank,
                meta: {
                    title: 'menu.content',
                    permission: 'Conteúdo',
                    filterPermission: true,
                    breadcrumbs: ['content']
                },
                children: [
                    {
                        path: 'files',
                        name: 'files',
                        component: () => import('@/views/content/files/index.vue'),
                        meta: {
                            title: 'menu.repository',
                            permission: 'Depósito',
                            filterPermission: true,
                            breadcrumbs: ['content', 'depot']
                        }
                    }
                ]
            },
            {
                path: 'conteudo',
                name: 'content',
                component: Blank,
                meta: {
                    title: 'menu.content',
                    permission: 'Conteúdo',
                    filterPermission: true,
                    breadcrumbs: ['content']
                },
                children: [
                    {
                        path: 'avaliacao',
                        name: 'tests',
                        component: () => import('@/views/avaliacao/index.vue'),
                        meta: {
                            title: 'menu.tests',
                            permission: 'Avaliações',
                            filterPermission: true,
                            breadcrumbs: ['content', 'tests']
                        }
                    },
                    {
                        path: 'curso',
                        name: 'courses',
                        component: () => import('@/views/curso/index.vue'),
                        meta: {
                            title: 'menu.courses',
                            permission: 'Cursos',
                            filterPermission: true,
                            breadcrumbs: ['content', 'courses']
                        }
                    },
                    {
                        path: 'certificados-externos-admin',
                        name: 'conteudo.certificados-externos-admin',
                        component: () => import('@/views/certificadoExternoAdmin/index.vue'),
                        meta: {
                            title: 'menu.manageExternalCertificates',
                            permission: 'Gerir Certificados Externos',
                            filterPermission: true,
                            breadcrumbs: ['content', 'manage-externalCertificates']
                        }
                    },
                    {
                        path: 'trilha',
                        name: 'formations',
                        component: () => import('@/views/formacao/index.vue'),
                        meta: {
                            title: 'menu.trails',
                            permission: 'Trilhas',
                            filterPermission: true,
                            breadcrumbs: ['content', 'formations']
                        }
                    },
                    {
                        path: 'deposito',
                        name: 'depot',
                        component: () => import('@/views/deposito/depositoIndex.vue'),
                        meta: {
                            title: 'menu.repository',
                            permission: 'Depósito',
                            filterPermission: true,
                            breadcrumbs: ['content', 'depot']
                        }
                    },
                    {
                        path: 'secao',
                        name: 'sections',
                        component: () => import('@/views/secao/secaoIndex.vue'),
                        meta: {
                            title: 'menu.sections',
                            permission: 'Seções',
                            filterPermission: true,
                            breadcrumbs: ['content', 'sections']
                        }
                    }
                ]
            },
            {
                // Sidebar Configuração
                path: 'settings',
                name: 'settings',
                component: Blank,
                meta: {
                    title: 'menu.settings',
                    permission: 'Configurações',
                    filterPermission: true,
                    breadcrumbs: ['settings']
                },
                children: [
                    {
                        path: 'personalizacoes',
                        name: 'customization',
                        component: () => import('@/views/configuracao/personalizar/index.vue'),
                        meta: {
                            title: 'menu.customizations',
                            permission: 'Personalizações',
                            filterPermission: true,
                            breadcrumbs: ['settings', 'customization']
                        }
                    },
                    {
                        path: 'config/login',
                        name: 'login-config',
                        component: () => import('@/views/configuracao/personalizar/components/login/settings.vue'),
                    },
                    {
                        path: 'termos-uso',
                        name: 'terms-of-use',
                        component: () => import('@/views/configuracao/termoDeUso/index.vue'),
                        meta: {
                            title: 'menu.termsOfUse',
                            permission: 'Termos de Uso',
                            filterPermission: true,
                            breadcrumbs: ['settings', 'terms-of-use']
                        }
                    },
                    {
                        path: 'segmentacao',
                        name: 'segmentations',
                        component: () => import('@/views/segmentacao/segmentacaoIndex.vue'),
                        meta: {
                            title: 'menu.segmentations',
                            permission: 'Segmentações',
                            filterPermission: true,
                            breadcrumbs: ['settings', 'segmentations']
                        }
                    },
                    {
                        path: 'competencia',
                        name: 'competences',
                        component: () => import('@/views/competencia/CompetenciaIndex.vue'),
                        meta: {
                            title: 'menu.skills',
                            permission: 'Competências',
                            filterPermission: true,
                            breadcrumbs: ['settings', 'competences']
                        }
                    },
                    {
                        path: 'permissoes',
                        name: 'permission-groups',
                        component: () => import('@/views/grupoPermissoes/index.vue'),
                        meta: {
                            title: 'menu.permissions',
                            permission: 'Permissões',
                            filterPermission: true,
                            breadcrumbs: ['settings', 'permission-groups']
                        }
                    },
                    {
                        path: 'categoria',
                        name: 'categories',
                        component: () => import('@/views/categoria/index.vue'),
                        meta: {
                            title: 'menu.categories',
                            permission: 'Categorias',
                            filterPermission: true,
                            breadcrumbs: ['settings', 'categories']
                        }
                    },
                    {
                        path: 'certificado',
                        name: 'certificates',
                        component: () => import('@/views/certificado/index.vue'),
                        meta: {
                            title: 'menu.certificates',
                            permission: 'Certificados',
                            filterPermission: true,
                            breadcrumbs: ['settings', 'certificates']
                        }
                    }
                ]
            },
            {
                path: 'gestao',
                name: 'management',
                component: Blank,
                meta: { title: 'menu.management', permission: 'Gestão', breadcrumbs: ['management'] },
                children: [
                    {
                        path: 'progresso',
                        name: 'management.progresso',
                        component: () => import('@/views/gestao/progresso/index.vue'),
                        meta: {
                            title: 'menu.myProgress',
                            permission: 'Meu Progresso',
                            filterPermission: true,
                            breadcrumbs: ['management', 'progress']
                        }
                    },
                    {
                        path: 'dashboard',
                        name: 'management.dashboard',
                        component: () => import('@/views/gestao/dashboard/gestor.vue'),
                        meta: {
                            title: 'menu.dashboard',
                            permission: 'Dashboard',
                            filterPermission: true,
                            breadcrumbs: ['management', 'dashboard']
                        }
                    },
                    {
                        path: 'meu-painel',
                        name: 'management.painel',
                        component: () => import('@/views/gestao/dashboard/cliente.vue'),
                        meta: {
                            title: 'menu.myPanel',
                            permission: 'Meu Painel',
                            filterPermission: true,
                            breadcrumbs: ['management', 'my-panel']
                        }
                    },
                    {
                        path: 'historico-envios',
                        name: 'management.envios',
                        component: () => import('@/views/gestao/envios/index.vue'),
                        meta: {
                            title: 'menu.uploadHistory',
                            permission: 'Histórico de Envios',
                            filterPermission: false,
                            breadcrumbs: ['management', 'upload-history']
                        }
                    },
                    {
                        path: 'importacoes',
                        name: 'management.importacoes',
                        component: () => import('@/views/gestao/importacoes/index.vue'),
                        meta: {
                            title: 'menu.importHistory',
                            permission: 'Histórico de Importações',
                            breadcrumbs: ['management', 'import-history']
                        }
                    },
                    {
                        path: 'bi-management',
                        name: 'management.bi',
                        component: () => import('@/views/gestao/bi/index.vue'),
                        meta: {
                            title: 'menu.bi',
                            permission: 'Painéis Dashboard',
                            filterPermission: true,
                            breadcrumbs: ['management', 'bi', 'bi-management']
                        }
                    },
                    {
                        path: 'bi-dashboard',
                        name: 'dashboard.bi',
                        component: () => import('@/views/gestao/vistra/index.vue'),
                        meta: {
                            title: 'menu.bi',
                            permission: 'Dashboard v2',
                            filterPermission: false,
                            breadcrumbs: ['management', 'bi', 'bi-dashboard']
                        }
                    },
                    {
                        path: 'certificados-externos',
                        name: 'gestao.certificadosExternos',
                        component: () => import('@/views/gestao/certificadosExternos/index.vue'),
                        meta: {
                            title: 'menu.externalCertificates',
                            permission: 'Certificados Externos',
                            filterPermission: true,
                            breadcrumbs: ['management', 'externalCertificates']
                        }
                    },
                    {
                        path: 'minhas-avaliacoes',
                        name: 'management.my-assessments',
                        component: () => import('@/views/gestao/avaliacao/components/listClient.vue'),
                        meta: {
                            title: 'labels.myAssessments',
                            permission: 'Minhas Avaliações',
                            breadcrumbs: ['management', 'my-assessments']
                        }
                    },
                    {
                        path: 'minhas-avaliacoes/:id',
                        name: 'management.view-my-assessment',
                        component: () => import('@/views/gestao/avaliacao/userView.vue'),
                        meta: {
                            title: 'labels.myAssessments',
                            permission: 'Minhas Avaliações',
                            breadcrumbs: ['management', 'my-assessments']
                        }
                    },
                    {
                        path: 'correcoes-avaliacoes',
                        name: 'management.corrections-assessments',
                        component: () => import('@/views/gestao/avaliacao/components/listManager.vue'),
                        meta: {
                            title: 'labels.myAssessments',
                            permission: 'Correções de Avaliações',
                            breadcrumbs: ['management', 'corrections-assessments']
                        }
                    },
                    {
                        path: 'correcoes-avaliacoes/:id',
                        name: 'management.corrections-assessments.view',
                        component: () => import('@/views/gestao/avaliacao/view.vue'),
                    },
                    {
                        path: 'avaliacao/:id/answer',
                        name: 'management.assessment.answer',
                        component: () => import('@/views/gestao/avaliacao/components/answerAssessment.vue'),
                    }
                ]
            },
            {
                path: 'gamificacao',
                name: 'gamification',
                component: Blank,
                meta: {
                    title: 'menu.gamification',
                    permission: 'Gamificação',
                    breadcrumbs: ['gamification']
                },
                children: [
                    {
                        path: 'regras',
                        name: 'gamificacao.regras',
                        component: () => import('@/views/gamificacao/regras/index.vue'),
                        meta: {
                            title: 'menu.rules',
                            permission: 'Regras',
                            breadcrumbs: ['gamification', 'rules']
                        }
                    },
                    {
                        path: 'desafios',
                        name: 'gamificacao.desafios',
                        component: () => import('@/views/gamificacao/desafios/index.vue'),
                        meta: {
                            title: 'menu.challenges',
                            permission: 'Desafios',
                            breadcrumbs: ['gamification', 'challenges']
                        }
                    },
                    {
                        path: 'jornada',
                        name: 'gamificacao.jornada',
                        component: () => import('@/views/gamificacao/journey/index.vue'),
                        meta: {
                            title: 'menu.journey',
                            permission: 'Jornada',
                            breadcrumbs: ['gamification', 'journey']
                        }
                    }
                ]
            },
            {
                path: 'comunicacoes',
                name: 'comunicacoes',
                component: Blank,
                meta: {
                    title: 'menu.communications',
                    permission: 'Comunicações',
                    filterPermission: true,
                    breadcrumbs: ['communications']
                },
                children: [
                    {
                        path: 'comunicacao',
                        name: 'comunicacoes.comunicacao',
                        component: () => import('@/views/comunicacoes/comunicacao/index.vue'),
                        meta: {
                            title: 'menu.communication',
                            permission: 'Comunicação',
                            filterPermission: true,
                            breadcrumbs: ['communications', 'communication']
                        }
                    },
                    {
                        path: 'notificacoes-automatizadas',
                        name: 'comunicacoes.automated-notifications',
                        component: () => import('@/views/comunicacoes/automatedNotifications/index.vue'),
                        meta: {
                            title: 'menu.automatedNotifications',
                            permission: 'Notificações Automatizadas',
                            filterPermission: true,
                            breadcrumbs: ['communications', 'automated-notifications']
                        }
                    }
                ]
            },
            {
                path: 'notifications',
                name: 'notifications',
                component: () => import('@/views/notifications/index.vue'),
                meta: { title: 'menu.notifications', permission: 'Notificações' }
            },
            {
                path: '/user-settings',
                name: 'user-settings',
                component: Blank,
                redirect: '/user-settings/plan',
                children: [
                    {
                        path: 'plan',
                        name: 'user-settings.plan',
                        component: () => import('@/views/settings/plan/index.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/view/:id',
        name: 'view',
        meta: { requiresAuth: false },
        component: () => import('@/views/public/index.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout.vue'),
        meta: { title: 'Até mais', requiresAuth: true }
    },
    {
        path: '/auth/sso-silent',
        name: 'auth.sso-silent',
        component: () => import('@/views/auth/sso-silent.vue')
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/403.vue'),
        meta: { title: 'menu.notAllowed' }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/404.vue')
    },
    // 404 - Precisa ficar ao final desse array
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(import.meta.env.BASE_URL), // Vite
    routes
});
const authMiddleware = (to, from, next) => {
    const loggedIn = useAuthStore().loggedIn;
    const canAccess = useGrupoPermissoesStore().canUserAccess(String(to.meta.permission));
    if (to.meta.requiresAuth && !loggedIn) {
        const redirect = useSessionStorage('redirect', '');
        redirect.value = to.fullPath;
        return next({ path: '/login' });
    }
    if (to.meta.filterPermission && !canAccess && loggedIn && to.name !== 'unauthorized') {
        return next('/403');
    }
    const { contract: inContract } = webStorage.getItem('client-mode') || {};
    if (to.path === '/partner' && inContract)
        return next('/conta/contrato');
    if (to.name === 'learn.content') {
        return next({
            name: 'learningCenter.classRoom.content',
            params: {
                step: to.params.step,
                str: to.params.str
            }
        });
    }
    return next();
};
router.beforeEach(authMiddleware);
export default router;
