import baseApi from './base.api';
import { toast } from '@/common/util';
import { useAuthStore, useVistraStore } from '@/stores';
export const vistraService = {
    ...baseApi,
    resource: 'PainelVistraBI',
    getBasicPanelAccess(asDashboard) {
        return baseApi
            .get([vistraService.resource, !asDashboard ? '' : 'Dashboard'].join('/'))
            .then(({ success, data }) => {
            return {
                success,
                data: (data?.map((item) => ({
                    uniqueId: item.id,
                    id: item.painelId,
                    name: item.painelNome,
                    public: item.publico,
                    contractsId: item.contratosIds,
                    params: item.parametros,
                    active: item.ativo
                })) || [])
            };
        })
            .catch((error) => {
            console.log('error', error);
            return {
                success: false,
                data: []
            };
        });
    },
    getBasicPanelUserAccess(userId) {
        return baseApi
            .get(`${this.resource}/getBasicPanelUserAccess`, { userId })
            .then(({ success, data }) => {
            return {
                success,
                data: (data?.map((item) => ({
                    uniqueId: item.id,
                    id: item.painelId,
                    name: item.painelNome,
                    public: item.publico,
                    contractsId: item.contratosIds,
                    active: item.ativo
                })) || [])
            };
        })
            .catch((error) => {
            console.log('error', error);
            return {
                success: false,
                data: []
            };
        });
    },
    getUsersFromPanelId(panelId) {
        return baseApi
            .get(`${this.resource}/getUsersFromPanelId`, { panelId })
            .then(({ success, data }) => {
            return {
                success,
                data: (data?.map(item => ({
                    id: item.id,
                    panelId: item.panelId,
                    userName: item.name,
                    email: item.email,
                    position: item.cargo,
                    unity: item.unidadeDeNegocio,
                    department: item.departamento,
                    hasAccess: item.hasAccess
                })) || [])
            };
        })
            .catch((error) => {
            console.log('error', error);
            return {
                success: false,
                data: []
            };
        });
    },
    login() {
        const clientId = window.configuration?.aliare?.credentials?.client_id || 'plantar-educacao';
        return baseApi
            .post(`${this.resource}/Login/${clientId}`)
            .then(({ data }) => {
            return {
                success: true,
                data: {
                    authToken: data.authToken || '',
                    refreshToken: data.refreshToken || '',
                    status: data.status ?? false,
                },
            };
        })
            .catch((error) => {
            console.error('Login error:', error);
            const notifications = error.response?.data?.notifications || [];
            toast.error({ message: notifications[0] || 'Erro ao autenticar usuário no Vistra BI' });
            return {
                success: false,
                data: {
                    authToken: null,
                    refreshToken: null,
                    status: false,
                },
            };
        });
    },
    async setParamsRequest({ panelId, params = [] }) {
        params = params || [];
        const biParams = [];
        params.forEach(el => {
            let value = el.value;
            if (`${el.type}`.toLowerCase() === 'metadata') {
                switch (el.value) {
                    case 'tenantId':
                        value = useAuthStore().getActiveTentant;
                        break;
                    case 'superiorId':
                        value = useAuthStore().persistentInfo.supervisor.id;
                        break;
                    case 'usuarioId':
                        value = useAuthStore().persistentInfo.id;
                        break;
                }
            }
            biParams.push({
                id: el.id,
                valor: value,
                descricao: el.description
            });
        });
        return baseApi
            .post(`${this.resource}/SetParametrosRequisicaoExterna`, {
            painel: panelId,
            parametros: biParams,
            token: useVistraStore().vistraToken,
        })
            .then(({ data }) => {
            return {
                success: true,
                data: {
                    status: data.status ?? false,
                    token: data.token ?? ''
                },
            };
        })
            .catch((error) => {
            console.error('SetParamsRequest error:', error);
            const notifications = error.response?.data?.notifications || [];
            toast.error({ message: notifications[0] || 'Erro ao definir parâmetros no Vistra BI' });
            return {
                success: false,
                data: {
                    status: false,
                    token: ''
                },
            };
        });
    }
};
