import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, unref as _unref, createSlots as _createSlots, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "text-center p-4"
};
const _hoisted_2 = {
    key: 1,
    class: "w-full"
};
const _hoisted_3 = { class: "font-semibold text-lg" };
const _hoisted_4 = { class: "text-base mb-4" };
const _hoisted_5 = { class: "relative" };
const _hoisted_6 = { class: "text-right flex gap-2 justify-end" };
const _hoisted_7 = ["data-tip"];
const _hoisted_8 = ["data-tip"];
const _hoisted_9 = ["data-tip"];
const _hoisted_10 = {
    key: 0,
    class: "absolute w-full h-full left-0 top-0 pointer-events-none flex items-center justify-center"
};
const _hoisted_11 = { class: "-rotate-45 text-[--c-primary-500] opacity-50 text-lg md:text-4xl lg:text-6xl text-center transition-all" };
import { computed, onBeforeMount, onBeforeUnmount, ref, nextTick } from 'vue';
import { useResizeObserver, watchDebounced, useFullscreen, useMagicKeys, whenever, useActiveElement } from '@vueuse/core';
import VuePdfEmbed from 'vue-pdf-embed';
import { clamp, debounce } from '@/common/util';
import { useAuthStore, useUsuariosStore } from '@/stores';
import UiAlert from '@/components/ui/alert.vue';
import UiButton from '@/components/ui/button.vue';
import UiIcon from '@/components/ui/icon.vue';
import PdfToolbar from './toolbar.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        source: {},
        allowPrinting: { type: Boolean },
        allowDownload: { type: Boolean },
        watermark: { type: Boolean },
        watermarkRules: {},
        customWatermark: {}
    },
    setup(__props, { expose: __expose }) {
        const auth = useAuthStore();
        const props = __props;
        const pdfEmbed = ref(null);
        const pdfWrapper = ref();
        const contentWrapper = ref();
        const timeFix = ref(0);
        const page = ref(1);
        const fPage = ref(1);
        const maxPageReached = ref(1);
        const loaded = ref(false);
        const pageReady = ref(false);
        const toolbarRef = ref();
        const status = ref('initial');
        const src = ref();
        const render = ref(true);
        const width = ref(0);
        const userIp = ref('');
        const { isFullscreen, toggle } = useFullscreen(contentWrapper);
        const activeElement = useActiveElement();
        const { ctrl_p, ctrl_s, alt_enter, arrowright, arrowleft, ctrl_arrowright, ctrl_arrowleft } = useMagicKeys({
            passive: false,
            onEventFired: (e) => activeElement.value?.tagName === 'BODY' && e.preventDefault(),
            target: contentWrapper.value
        });
        whenever(ctrl_p, () => print());
        whenever(ctrl_s, () => download());
        whenever(alt_enter, () => toggle());
        whenever(ctrl_arrowright, () => nextTick(() => toolbarRef.value?.lastPage()));
        whenever(ctrl_arrowleft, () => nextTick(() => toolbarRef.value?.firstPage()));
        whenever(arrowright, () => toolbarRef.value?.nextPage());
        whenever(arrowleft, () => toolbarRef.value?.previousPage());
        const pdfData = computed(() => ({
            page: 1,
            pageCount: pdfEmbed.value?.doc?._pdfInfo?.numPages || null
        }));
        const autoWatermark = computed(() => {
            if (props.customWatermark)
                return Array.isArray(props.customWatermark) ? props.customWatermark : [props.customWatermark];
            const fields = [];
            if (props.watermark) {
                const { username, email, company, unit, department, position, dateTime, ip } = props.watermarkRules;
                if (username)
                    fields.push(auth.userData?.nomeCompleto);
                if (email)
                    fields.push(auth.userData?.email);
                if (company)
                    fields.push(useUsuariosStore().partnerList.find(p => p.tenantId === auth.getActiveTentant)?.accountName);
                if (unit)
                    fields.push(auth.userData.unidadeDeNegocioNome);
                if (department)
                    fields.push(auth.userData?.departamentoNome);
                if (position)
                    fields.push(auth.persistentInfo.role);
                if (dateTime)
                    fields.push((new Date().toLocaleDateString('pt-br') + ' ' + new Date().toLocaleTimeString('pt-br')));
                if (ip && userIp.value)
                    fields.push(`IP:${userIp.value}`);
            }
            return fields;
        });
        const onRenderingFailed = () => {
            if (!pdfData.value.pageCount)
                status.value = 'error';
        };
        const onProgress = (progress) => {
            if (progress.loaded !== progress.total && status.value !== 'loading')
                status.value = 'loading';
            else if (progress.loaded === progress.total)
                status.value = 'success';
        };
        const doneRendering = () => {
            pageReady.value = true;
            status.value = 'success';
        };
        const doneLoading = () => {
            loaded.value = true;
        };
        watchDebounced(() => page.value, (val) => {
            fPage.value = clamp(val, 1, pdfData.value.pageCount);
            page.value = fPage.value;
            if (val > maxPageReached.value)
                maxPageReached.value = fPage.value;
        }, { debounce: 200, maxWait: 2000 });
        useResizeObserver(pdfWrapper, (entries) => {
            const { width: cWidth } = entries?.[0].contentRect || {};
            if (cWidth)
                debounce(() => width.value = cWidth, 200)();
        });
        const download = () => pdfEmbed.value?.download(`${props.source}`.split('/').pop());
        const print = () => pdfEmbed.value?.print(100, 'pdf', true);
        onBeforeUnmount(() => {
            pageReady.value = false;
            status.value = 'initial';
        });
        onBeforeMount(() => {
            timeFix.value = Date.now();
            src.value = props.source;
            if (props.watermarkRules?.ip) {
                fetch('https://api.ipify.org?format=json')
                    .then(res => res.json())
                    .then(data => {
                    userIp.value = data?.ip;
                });
            }
        });
        const jumpToPercent = async (percent) => {
            page.value = Math.round(clamp((percent / 100) * pdfData.value?.pageCount, 1, pdfData.value?.pageCount));
        };
        const setPage = (pageNum) => {
            page.value = clamp(pageNum, 1, pdfData.value?.pageCount);
        };
        const reloadRender = () => {
            render.value = false;
            status.value = 'loading';
            timeFix.value = Date.now();
            setTimeout(() => {
                status.value = 'success';
                render.value = true;
            }, 2000);
        };
        __expose({
            pageCount: computed(() => pdfData.value.pageCount),
            page: computed(() => page.value),
            jumpToPercent,
            setPage
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                ref_key: "contentWrapper",
                ref: contentWrapper
            }, [
                (status.value === 'loading')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("div", null, [
                            _createVNode(UiIcon, {
                                icon: "loader",
                                class: "animate-spin"
                            })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('feedback.loading')), 1)
                    ]))
                    : (status.value === 'error')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(UiAlert, {
                                type: "danger",
                                class: "mb-6 max-w-2xl mx-auto"
                            }, {
                                icon: _withCtx(() => [
                                    _createVNode(UiIcon, {
                                        icon: "file-alert",
                                        size: "42"
                                    })
                                ]),
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('dialogs.ops')), 1),
                                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('feedback.errorLoadObject', { object: _ctx.$t('labels.pdf') })), 1),
                                    _createVNode(UiButton, {
                                        type: "danger",
                                        class: "p-2",
                                        onClick: _cache[0] || (_cache[0] = ($event) => (reloadRender()))
                                    }, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('button.tryAgain')), 1)
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                    (render.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            ref_key: "pdfWrapper",
                            ref: pdfWrapper,
                            key: timeFix.value,
                            class: _normalizeClass(["relative", { 'max-h-screen overflow-x-hidden overflow-y-auto': _unref(isFullscreen) }])
                        }, [
                            (_openBlock(), _createBlock(_unref(VuePdfEmbed), {
                                ref_key: "pdfEmbed",
                                ref: pdfEmbed,
                                source: `${src.value}?${timeFix.value}`,
                                key: `${src.value}?${timeFix.value}`,
                                page: _unref(clamp)(fPage.value, 1, pdfData.value.pageCount || 1),
                                width: width.value,
                                onRendered: _cache[4] || (_cache[4] = () => doneRendering()),
                                onLoaded: _cache[5] || (_cache[5] = () => doneLoading()),
                                onRenderingFailed: onRenderingFailed,
                                onProgress: onProgress
                            }, _createSlots({
                                "before-page": _withCtx(() => [
                                    _createVNode(PdfToolbar, {
                                        modelValue: page.value,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((page).value = $event)),
                                        pageCount: pdfData.value.pageCount,
                                        "max-page": maxPageReached.value
                                    }, {
                                        actions: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_6, [
                                                (_ctx.allowDownload)
                                                    ? (_openBlock(), _createElementBlock("div", {
                                                        key: 0,
                                                        class: "tooltip tooltip-bottom",
                                                        "data-tip": _ctx.$t('button.download', { text: '(Ctrl + S)' })
                                                    }, [
                                                        _createVNode(UiButton, {
                                                            type: "ghost",
                                                            class: "p-2",
                                                            onClick: download
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(UiIcon, { icon: "download" })
                                                            ]),
                                                            _: 1
                                                        })
                                                    ], 8, _hoisted_7))
                                                    : _createCommentVNode("", true),
                                                (_ctx.allowPrinting)
                                                    ? (_openBlock(), _createElementBlock("div", {
                                                        key: 1,
                                                        class: "tooltip tooltip-bottom",
                                                        "data-tip": _ctx.$t('button.print', { text: '(Ctrl + P)' })
                                                    }, [
                                                        _createVNode(UiButton, {
                                                            type: "ghost",
                                                            class: "p-2",
                                                            onClick: print
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(UiIcon, { icon: "printer" })
                                                            ]),
                                                            _: 1
                                                        })
                                                    ], 8, _hoisted_8))
                                                    : _createCommentVNode("", true),
                                                _createElementVNode("div", {
                                                    class: "tooltip tooltip-bottom",
                                                    "data-tip": !_unref(isFullscreen) ? _ctx.$t('button.fullscreen', ['', '(Alt + Enter)']) : _ctx.$t('button.fullscreen', [_ctx.$t('button.exit'), '(Alt + Enter)'])
                                                }, [
                                                    _createVNode(UiButton, {
                                                        type: "ghost",
                                                        class: "p-2",
                                                        onClick: _cache[1] || (_cache[1] = ($event) => (_unref(toggle)()))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(UiIcon, {
                                                                icon: !_unref(isFullscreen) ? 'maximize' : 'minimize'
                                                            }, null, 8, ["icon"])
                                                        ]),
                                                        _: 1
                                                    })
                                                ], 8, _hoisted_9)
                                            ])
                                        ]),
                                        _: 1
                                    }, 8, ["modelValue", "pageCount", "max-page"])
                                ]),
                                _: 2
                            }, [
                                (pdfData.value?.pageCount && pdfData.value.pageCount > 1)
                                    ? {
                                        name: "after-page",
                                        fn: _withCtx(() => [
                                            _createVNode(PdfToolbar, {
                                                ref_key: "toolbarRef",
                                                ref: toolbarRef,
                                                modelValue: page.value,
                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((page).value = $event)),
                                                pageCount: pdfData.value.pageCount,
                                                "max-page": maxPageReached.value
                                            }, null, 8, ["modelValue", "pageCount", "max-page"])
                                        ]),
                                        key: "0"
                                    }
                                    : undefined
                            ]), 1032, ["source", "page", "width"])),
                            (loaded.value && pdfData.value?.pageCount && pageReady.value && autoWatermark.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createElementVNode("div", _hoisted_11, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(autoWatermark.value, (text, index) => {
                                            return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(text), 1));
                                        }), 128))
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ], 2))
                        : _createCommentVNode("", true)
                ])
            ], 512));
        };
    }
});
